export default {
    actions : {
        storeProcessEFT(ctx, data){
            ctx.commit('updatedProcessEFTData',data)
        },
        storeCompleteEFTransferData(ctx,data){
            ctx.commit('updatedCompleteEFTTransferData',data)
        },
        storeViewTransactionData(ctx,data){
            ctx.commit('updatedViewTransactionData',data)
        },
        storeInitiateETransferDetails(ctx, data) {
            ctx.commit('updatedInitiateEtransferDetails', data)
        }
    },
    mutations : {
        updatedProcessEFTData(state,data){
            state.processEFTData = data
        },
        updatedCompleteEFTTransferData(state,data) {
            state.completeETransferData = data
        },
        updatedViewTransactionData(state,data){
            state.viewTransactionData = data
        },
        updatedInitiateEtransferDetails(state, data) {
            state.initiateEtransfer = data
        }
    },
    getters : {
        getProcessEFTData (state) {
            return state.processEFTData
        },
        getCompleteEFTransferData(state) {
            return state.completeETransferData
        },
        getViewTransactionData(state){
            return state.viewTransactionData
        },
        getInitiateEtranferDetails(state) {
            return state.initiateEtransfer
        }
       
    },
    state : {
        processEFTData : {},
        completeETransferData : {},
        viewTransactionData : {},       
        initiateEtransfer : null
    }
}