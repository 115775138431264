<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'

export default {
    name: 'HomeService',
    methods: {
        GetUserSummary (data) {
            return _request('csGetUsersSummary', data).then(Parser.toXml.bind(null,'csGetUsersSummaryResponse'))
        },
        GetReferralDetails (customerEmail, toDate, fromDate) {
            return _request('csGetReferralSummary', {'ReferrerEmail':customerEmail, 'DateRangeMin': toDate, 'DateRangeMax': fromDate}).then(Parser.toXml.bind(null,'csGetReferralsResponse'))
        },
        ChangePassword (newPassword) {
            return _request('ChangePassword', {'NewPassword' : newPassword}).then(Parser.toXml.bind(null,'ChangePasswordResponse'))
        },
        GetTransactionSummary (data) {
            return _request('csGetTransactionSummary', data).then(Parser.toXml.bind(null,'csTransactionSummaryResponse'))
        }
    }
    
}
</script>