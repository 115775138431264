<template>
  <div
    id="demo"
    :class="[{'collapsed' : collapsed}, {'onmobile' : isOnMobile}]"
  >
    <div class="demo">
      <div class="container-new">      
        <router-view />
      </div>
      <AppHeader v-if="showAppSidebar"/>    
      <Sidebar v-if="showAppSidebar" @toggle-collapse="onToggleCollapse" v-bind:selected-theme='selectedTheme' />
      <div
        v-if="isOnMobile && !collapsed"
        class="sidebar-overlay"
        @click="collapsed = true"
      ></div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import Sidebar from '@/components/sidebar/sidebar'
import AppHeader from '@/components/header/Header'

const separator = {
  template: `<hr style="border-color: rgba(0, 0, 0, 0.1); margin: 20px;">`
}

export default {
  name: 'App',
  components : {
    Sidebar,AppHeader
  },
  data () {
    return {
      allowedRouter : ['AmendAccount','Dashboard','UserSummary','BankAccount','ViewAccount','AmendRecipient','AddPromotion','BulkPromotion','ViewPromotion','Referral','ResendActivationMail','ProcessEft','EFTTransaction',
       'CompleteETransfer','CompleteEFTTransaction','CompleteInteracPSIGate','CompleteInteracMoneris','ReviewMonerisTransaction','EmailTransactionInvoice','SendMoney','ResendMoneyTransfer','SendMoneyForCustomer','TransactionTrace',
      'ViewTransactions','PrintTransaction','FXTrasactionSummary','FXRate','AddFXTransationSummary','FxTransaction','FxAccoount','GetExchangeRate','ViewProfile','ChangePassword','ETransfer','InitiateETransfer','CompleteEFT','InitiateEFT',
      'InitiateEFTProcess','TransactionReports','ReviewETransfer','GetRecipients','Confirmation','SaltReview','MenuRights', 'ManageRights', 'AccountAdjustment', 'CustomerReports', 'ReferralReport', 'TransStatusByDestCountry', 'PromoUsageReports',
      'TransStatusByDestCountryByDay', 'TransStatusByDevice', 'TransStatusByRemittanceGateway', 'UserActiveByMonth', 'UserSignedUpByMonth', 'CustomerRecentLogins', 'FxOverrideWrite', 'ManageFees'],
      collapsed: false,
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],
      selectedTheme: '',
      isOnMobile: false
    }
  },  
  computed : {
    ...mapGetters(['getUsername', 'getUserAclRights']),
    showAppSidebar(){
      return this.allowedRouter.includes(this.$route.name)
    }
  },
  methods: {
    ...mapActions(['reserveUserName']),
    onToggleCollapse (collapsed) {
      this.collapsed = collapsed
    },
    onResize () {
      if (window.innerWidth <= 876) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    }
  },
  async mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
    // user name
    let userInfo = await JSON.parse(localStorage.getItem('user-info'))    
    this.reserveUserName(userInfo?.FirstName._text  + ' ' + userInfo?.LastName._text)
  }
}
</script>

<style src='@../../../static/css/common.css'>
</style>