<template src="./header.html"></template>
<script>
import { mapGetters } from 'vuex'
import AuthenticationService from '@/services/AuthenticationService'
export default {
    name: 'App',
    data () {
        return {
            currentDateTime: new Date()
        }
    },  
    computed : {
       ...mapGetters(['getUsername'])
    },
    methods: {
        logout() {
            AuthenticationService.methods.logout()
            this.$router.push({
                name: 'Login'
            })
        }
    },
    async mounted () {
        setInterval( () => {
        this.currentDateTime = new Date()
        },1000 )
    }
}
</script>