<script>
import axios from 'axios'
import appConstants from '@/constants/AppConstants'
import utils from '@/utils/utils'
import { BehaviorSubject } from 'rxjs'

const authUserSubject = new BehaviorSubject(localStorage.getItem('user-auth'))
export default {
    name: 'AuthenticationService',
    authUser: authUserSubject.asObservable,
    get authUserValue() {
        return authUserSubject.value
    },
    methods : {
        login (userCredentials) {
            const data = JSON.stringify({
                ClientAppId: appConstants.logInBodyRequest.ClientAppId,
                ClientAppVersion: appConstants.logInBodyRequest.ClientAppVersion,
                CustomerEmail : userCredentials.username
            })
            return axios 
                .post (
                    appConstants.publicServerBaseUrl + 'csGetUserProfile',
                    data,
                    {
                        crossdomain: true,
                        headers: {
                            Accept: '*/*',
                            'Access-Control-Allow-Origin': '*',
                            'Content-Type': 'application/json',
                            common: {
                                Authorization: `Basic ${btoa(
                                userCredentials.username + ':' + userCredentials.password
                                )}`
                            }
                        }
                    }
                )
                .then((res) => {
                    const response = utils.methods.getParseObjectFromXml(res.data)
                    if (+response.csGetUserProfileResponse.ErrorCode._text !== 1 ) {
                        return new Promise((resolve, reject) => 
                            reject(
                                new Error(
                                    response.csGetUserProfileResponse.ErrorDescription._text
                                )
                            )
                        )                        
                    } else {
                        const auth = res.config.headers.Authorization
                        localStorage.setItem('user-auth', auth)
                        localStorage.setItem('user-info', JSON.stringify(response.csGetUserProfileResponse))
                        authUserSubject.next(auth)
                        return new Promise ( resolve => resolve(response))
                    }
                })           
        },
        logout () {                 
            authUserSubject.next(null)
            localStorage.removeItem('user-auth')
            localStorage.removeItem('user-info')
            localStorage.removeItem('recipientData')
            localStorage.removeItem('printTransaction')
            localStorage.removeItem('userAclRights')
        }
    }
}
</script>