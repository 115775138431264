<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'
export default {
    name: 'FXServices',
    methods : {       
        GetFxAccount () {
            return _request('csFxGetAccounts', {}).then(Parser.toXml.bind(null,'csFxGetAccountsResponse'))
        },
        GetFxRate () {
            return _request('csGetFxRates', {}).then(Parser.toXml.bind(null,'csGetFxRatesResponse'))
        },
        UpdateFxRate (destCurrency, buyRate, markup, provider) {
            return _request('csUpdateFxRate', {
                'SourceCurrency' : 'CAD',
                'DestCurrency'   : destCurrency,
                'BuyRate'        : buyRate,
                'Markup'         : markup,
                'FXProvider'     : provider
            }).then(Parser.toXml.bind(null,'csUpdateFxRateResponse'))
        },
        GetFXTransactionSummary(data) {
            return _request('csGetFxTransactionSummary',data).then(Parser.toXml.bind(null,'csGetFXTransactionSummaryResponse'))
        },
        GetFXAccountList(fxProviderName){
            return _request('csFxGetAccounts',{FXProviderName: fxProviderName}).then(Parser.toXml.bind(null,'csFxGetAccountsResponse'))
        },
        AddFxTransactionNote(data) {
            return _request('csAddFXTransactionNote',data).then(Parser.toXml.bind(null,'csAddFXTransactionNoteResponse'))
        },
        CancelFxTransaction(data) {
            return _request('csCancelManualFXTransaction',data).then(Parser.toXml.bind(null,'csCancelManualFXTransactionResponse'))
        },
        GetExchangeRate(data) {
            return _request('csGetExchangeRate',data).then(Parser.toXml.bind(null,'csGetExchangeRateResponse'))
        },
        updateManualFxTransactionRecored (data) {
            return  _request('csRecordManualFXTransaction', data).then(Parser.toXml.bind(null,'csRecordManualFXTransactionResponse'))
        },
        AddFXOverrideRate (data) {
            return  _request('csAddFXOverrideRate', data).then(Parser.toXml.bind(null,'csAddFXOverrideRateResponse'))
        },
        GetFees (data) {
            return  _request('csGetFees', data).then(Parser.toXml.bind(null,'csGetFeesResponse'))
        },
        ManageFees (data) {
            return  _request('csManageFees', data).then(Parser.toXml.bind(null,'csManageFeeResponse'))
        }
    }
}
</script>