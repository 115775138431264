<script>
export default {
     transactionStatus :[{
        Text: 'Completed',
        Value : '1'
    },
    {
        Text: 'Insufficient fund',
        Value : '5'
    },
    {
        Text: 'Initiated',
        Value : '10'
    },
    {
        Text: 'Cancelled',
        Value : '15'
    },
    {
        Text: 'Failed',
        Value : '17'
    },
    {
        Text: 'ReportedAsFailed', //by PSI Gate
        Value: '26'
    },
    {
        Text: 'Pending',
        Value : '27'
    },
    {
        Text: 'Verification Failed',
        Value : '32'
    },
    {
        Text: 'Waiting for Funding',
        Value : '35'
    },
    {
        Text: 'Transaction Queued',
        Value : '36'
    },
    {
        Text: 'Marked For Processing',
        Value : '38'
    },
    
    {
        Text: 'Processing',
        Value : '39'
    },
    {
        Text: 'Rejected ',
        Value : '40'
    },
    {
        Text: 'Reported as Returned',
        Value : '41'
    },
    {
        Text: 'Failed after Funded',
        Value : '42'
    },
    {
        Text: 'Beneficiary not Allowed for Remit',
        Value : '52'
    },
    {
        Text: 'Sender Compliance Pending',
        Value : '56'
    },
    {
        Text: 'Forced Failure on First Transaction',
        Value : '57'
    },
    {
        Text: 'Hold for Approval',
        Value : '58'
    },
    {
        Text: 'Technical Error',
        Value : '100'
    }],
    transactionPurpose :[{
        Text: 'Transfer to Family Member or Friend',
        Value: '1'
    },
    {
        Text :'Transfer to Personal Bank Account',
        Value : '2'
    }],
    transactionTypes: [
        { Text: "Interac Transfer", Value: "2"},
        { Text: "Money Transfer", Value: "3"},
        { Text: "PromoCode Redeeming", Value: "4"},
        { Text: "E-Transfer", Value: "5"},
        { Text: "Electronic Fund Transfer", Value: "6"}
    ]
}
</script>
