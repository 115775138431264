import FxService from '@/services/FXServices.vue'
import AccConstants from '@/constants/AccountConstants.vue'
import transactionConstants from '@/constants/TransactionConstants.vue'

export default {
    actions : {
        async fetchFxAccount(ctx) {
            const fxAccountInfo = await FxService.methods.GetFxAccount ()
            ctx.commit(
                'updatedFxAccount',
                fxAccountInfo.csFxGetAccountsResponse.FxAccountList.FXAccountListItem
            )
        },
        async fetchFxRate(ctx) {
            const fxRateInfo = await FxService.methods.GetFxRate ()
            ctx.commit(
                'updatedFxRate',
                fxRateInfo.csGetFxRatesResponse.Rates.CurrencyRateElement
            )
        },
        storeFXTransactionSummaryData(ctx,data) {
            ctx.commit('updatedFXTransactionSummaryData',data)
        }, 
        async fetchFees(ctx) {
            const fees = await FxService.methods.GetFees()
            ctx.commit('updateFeesData', fees.csGetFeesResponse.FeeData.FeeData)
        }
    },
    mutations: {
        updatedFxAccount (state, accountInfo) {
            const mapAccountInfo  =  (info) => {
                Object.values(info).map((el, key) => {
                    const objectkey = Object.keys(info)[key]
                    info[objectkey] = el._text
                })
            }
           accountInfo.map(mapAccountInfo) 
           state.fxAccountDetails =  accountInfo
        },
        updatedFxRate (state, rateInfo) {
            const mapRateinfo = (info) => {
                Object.values(info).map((el, key) => {
                    const objectkey = Object.keys(info)[key]
                    info[objectkey] = el._text
                })
            }
            rateInfo.map(mapRateinfo)
            state.fxRateDetails = rateInfo
        },
        updatedFXTransactionSummaryData(state,data) {
            state.fXTransctionSummaryData = data
        },
        updateFeesData(state, data) {
            data.forEach(item => {
                Object.keys(item).map(key => item[key] = item[key]._text ? item[key]._text : null)
            })
            state.fees = data.map(feeItem => {
                return {
                    "FeeFlat": feeItem['FeeFlat'],
                    "FeePercentage": feeItem['FeePercentage'],
                    "Id": feeItem['Id'],
                    "TransactionDestination": feeItem['TransactionDestination'],
                    "TransactionDestinationType": feeItem['TransactionDestinationType'],
                    "TransactionSource": feeItem['TransactionSource'],
                    "TransactionSourceTypeName": feeItem['TransactionSourceType'],
                    "TransactionDestinationName": AccConstants.remittanceGatewayId.find(item => item.Value == feeItem['TransactionDestination'])?.Text ?? null,
                    "TransactionDestinationTypeName": AccConstants.transactionDestinationTypes.find(item => item.Value == feeItem['TransactionDestinationType'])?.Name ?? null,
                    "TransactionSourceName": AccConstants.fundingSources.find(item => item.Value == feeItem['TransactionSource'])?.Name ?? null,
                    "TransactionSourceType": AccConstants.transactionSourceTypes.find(item => item.Value == feeItem['TransactionSourceType'])?.Name ?? null,
                    "TransactionType": feeItem["TransactionType"],
                    "TransactionTypeName": transactionConstants.transactionTypes.find(item => item.Value == feeItem['TransactionType'])?.Text ?? null
                }
            })
            
        }
    },
    getters : {
        getFxAccountDetails (state) {
            return state.fxAccountDetails
        },
        getRatesDetails(state) {
            return state.fxRateDetails
        },
        getFXTransactionSummaryData(state) {
            return state.fXTransctionSummaryData
        },
        getFees(state) {
            return state.fees
        }
    },
    state : {
        fxAccountDetails : null,
        fxRateDetails : null ,
        fXTransctionSummaryData : {},
        fees: []
    }
}