import HomeService from '@/services/HomeService'

export default {
    actions: {
        async fetchNonZeroAccountUser (ctx) {
            const nonZeroAccountUser = await HomeService.methods.GetUserSummary({'AccountBalanceGreaterThan': '0'})
            ctx.commit(
                'updatedNonZeroAccountUser',
                nonZeroAccountUser.csGetUsersSummaryResponse
            )
        },
        async fetchReviewRequiredUser (ctx) {
            await HomeService.methods.GetUserSummary({'EidStatus':'2'})
            .then(async res => {
                await HomeService.methods.GetUserSummary({ 'EidStatus': null, 'MrmsStatus': '2' })
                .then(ress => {  
                    const response = res.csGetUsersSummaryResponse.UsersSummaryData.UserSummaryData.concat(ress.csGetUsersSummaryResponse.UsersSummaryData.UserSummaryData)             
                    ctx.commit(
                        'updatedReviewRequiredUser',
                         response
                    )
                })
            })
            
        },
        async fetchSignedUser (ctx) {
            const singnedUser = await HomeService.methods.GetUserSummary({'AccountStatus': '4'})
            ctx.commit(
                'updatedSignedUser',
                singnedUser.csGetUsersSummaryResponse
            )
        },
        async fetchETranferWaitingFund (ctx) {
            const eTranferWaitingFund = await HomeService.methods.GetTransactionSummary({'TransactionType': '5','TransactionStatus': '35'})            
            ctx.commit(
                'updatedETranferWaitingFund',
                eTranferWaitingFund.csTransactionSummaryResponse
            )
        },
        async reserveUserName (ctx, username) {
            ctx.commit('updatedUsername', username)
        }
    },
    mutations: {
        updatedNonZeroAccountUser (state, nonZeroAccountUser) {
            state.nonZeroAccountUser = nonZeroAccountUser               
        },
        updatedReviewRequiredUser (state, reviewRequiredUser) {
            state.reviewRequiredUser = reviewRequiredUser
        },
        updatedSignedUser (state, signedupUser) {
            state.signedupUser = signedupUser    
        },
        updatedETranferWaitingFund (state, eTranferWaitingFund) {           
            state.eTranferWaitingFund = eTranferWaitingFund
        },
        updatedUsername (state, name) {
            state.username = name
        }
    },
    getters: {
        getNonZeroAccountuser (state) {
            return state.nonZeroAccountUser
        },
        getReviewRequiredUser (state) {
            return state.reviewRequiredUser
        },
        getSignedUser(state) {
            return state.signedupUser
        },
        getETransferWaitingFund (state) {
            return state.eTranferWaitingFund
        },
        getUsername (state) {
            return state.username
        }
    },
    state : {
        nonZeroAccountUser : null,
        reviewRequiredUser : null,
        signedupUser : null,
        eTranferWaitingFund : null,
        username : ''
    }
}