<script>
export default {
    name: 'applicationConstants',
    publicServerBaseUrl: process.env.VUE_APP_PUBLICSERVERBASEURL,
    intercomAppId: process.env.VUE_APP_INTERCOMAPPID,
    logInBodyRequest: {
        ClientAppId:  process.env.VUE_APP_CLIENTAPPID,
        ClientAppVersion:  process.env.VUE_APP_CLIENTAPPVERSION
    },
    JsonServerUrl: {
        permission:  process.env.VUE_APP_JSONSERVERPERMISSION,
        privilege:  process.env.VUE_APP_JSONSERVERPRIVILEGE
    }, 
    formsMessages: {
        email: {
            required: 'Please enter email address.',
            invalid: 'Please enter a valid email address.'
        },
        name : value => {
            return { required: `${value} is required` }  
        },
        valueField : value => {
            return { required : `Please enter ${value}` }
        },
        selectField : value => {
            return  { required : `Please select ${value}` }
        },
        option : name => {
            return {
                required : `Please select ${name}`
            }
        }
        
    },
    errorCodes: {
        0: "Server Busy",
        1: "Success",
        2: "Unable to retrieve account data",
        3: "Account not enabled",
        4: "Invalid login data",
        5: "Invalid account name",
        6: "Unable to retrieve transactions history",
        7: "Invalid amount",
        8: "Insufficient funds",
        9: "Insufficient funds",
        10: "Recipient account is not active = ",
        11: "Account not found",
        12: "Recipient account not found",
        13: "Invalid parameter",
        14: "Source account should differ from target account",
        15: "Invalid payment destination",
        16: "Invalid recipient",
        17: "Transaction already initiated",
        18: "Transaction not found",
        19: "Transaction verification failed",
        20: "Unable to cancel transaction",
        21: "Invalid password",
        22: "Requested money remittance service not found",
        23: "Unable to remit money through given service",
        24: "Invalid operation",
        25: "Recipient not found",
        26: "Invalid user profile data",
        27: "Invalid user profile data",
        28: "Invalid email address",
        29: "Transactions limit exceeded",
        30: "Promo code already redeemed",
        31: "Promo code expired",
        32: "Invalid promo code",
        33: "Invalid promo code currency",
        34: "Not authorized",
        35: "Transaction already complete",
        36: "Invalid customer",
        37: "Fraudulent User",
        38: "Possible Fraudulent User",
        39: "Fraudulent Charge",
        40: "Possible Fraudulent Charge",
        41: "Invalid Date Of Birth",
        42: "Age Verification Failed",
        43: "UserId Verification Failed",
        44: "Account Closed",
        45: "Account Activation Code Expired",
        46: "Account Already Active",
        47: "Invalid Account Activation Code",
        48: "Invalid Account Status",
        49: "Invalid Transaction Purpose",
        50: "Invalid User Detail Fields",
        51: "User Profile Marked For Manual Review By Equifax EID = ",
        52: "User Profile Rejected By Equifax EID",
        53: "Invalid Phone Number",
        54: "Service Not Supported In Your Area",
        55: "Transaction pending",
        56: "Customer mobile number not verified",
        57: "Initiate Phone Verification Failed",
        58: "Code does not matched with expected value",
        59: "Complete Phone Verification Failed",
        60: "Client Version nearing end of Life",
        61: "Client or version not supported",
        62: "Previous Phone Verification Request is Pending",
        63: "Phone Number Already in Use",
        64: "InValid From Or To Date",
        65: "Promo Code already exists",
        66: "Invalid User For this Promo Code Redemption",
        67: "This Promo Code can be redeemed on first transaction only",
        68: "Unable To Retrive Promo Data",
        69: "Invalid Promo Code Expiry Date",
        71: "Error Adding Promo Code",
        72: "Invalid Promo Type",
        73: "Invite Code Cannot Be Redeemed",
        74: "Moneris First Stage Verification Failed",
        75: "Moneris Second Stage Verification Failed",
        76: "Invalid Transaction Type",
        77: "First Stage Verification Failed",
        78: "Moneris Transaction Initiation Failed",
        79: "Transaction Cancelled",
        80: "Invalid Invite Code",
        81: "User is not authorized for a given operation",
        82: "Remittance Gateway Not Enabled",
        83: "Invalid Bank Account",  //EFT Bank Accounts
        84: "Bank Account Not Found",  //EFT Bank Accounts
        85: "Bank Account Already Exist",  //EFT Bank Accounts
        86: "Invalid Bank Institution Number",
        87: "Invalid Bank Transit Number",
        88: "Invalid Transaction Amount",
        89: "Bank Account Not Verified",
        90: "EFT Account Verification Failed",
        91: "EFT Account Verification Exceeded Max Attempts",
        92: "Bank Account Verification InProcess",
        93: "Bank Account Verification Pending",
        94: "FX BuyRate Requied Only For PreFunding",
        95: "FXTransction Status Other Than Completed Trade Pending Payment",
        96: "No Bank List Defined",
        97: "Unable To Retrieve Transaction Status",
        98: "Invalid Reference Number",
        99: "Sender NotValid",
        100: "Operation Not Supported",
        101: "Unable To Retrieve Exchange Rate",
        102: "Invalid Country",
        103: "Invalid Code",
        104: "SMSSending Failed",
        105: "Offer Issued Already",
        106: "Promo Offer Not Available",
        107: "Bank Account Requires Update",
        //FlinksErrors
        151: "Retry Is Required",
        152: "Invalid Login Id Or Session",
        153: "Account Disabled",
        154: "New Account Found",
        155: "Already Authorized Session",
        156: "Disabled Institution",
        157: "End Point Not Reachable",
        158: "No Transaction",
        //Block Money Transfer based on compliance
        159: "Compliance Pending",
        //Force First Money Transfer to Fail
        160: "Forced Failure On First Transaction",
        161: "Hold For Approval",
        1000: "General error",
        2000: "Promo amount was capped due to remittance gateway fee",
        2001: "Error Generating Invite Code",
        2002: "Client version nearing end of life",
        2003: "User Profile Marked For Manual Review By Equifax EID (warning)",
        2004: "User Profile Rejected By Equifax EID (warning)",        
        3001: "User is not enabled",
        3002: "Email is not verified",
        3003: "User profile has not been verified",
        3004: "User is under review",
        3005: "Mobile is not verified",
        3006: "Transaction limit exceeded",
        3007: "Error occured while saving Manual transaction. Please check logs for details.",
        3008: "Error occured while cancelling Manual transaction. Please check logs for details."
    },
    enableLazyLoading: false
}
</script>