import Vue from 'vue'
import Vuex from 'vuex'
import account from './modules/account'
import home from './modules/home'
import bank from './modules/bank'
import promo from './modules/promo'
import fx from './modules/fx'
import transaction from './modules/transaction'
import rights from './modules/rights'

Vue.use(Vuex)


export default new Vuex.Store({  
  modules: {
    account,
    home,
    bank,
    promo,
    fx,
    transaction,
    rights    
  }
})
