<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'


export default {
    name: 'BankService',
    methods: {
        GetBankDetails (email) {
            return _request('csGetBankAccounts', { 'CustomerEmail': email }).then(Parser.toXml.bind(null,'csGetBankAccountsResponse'))
        },
        RefreshBankDetails (changeBankAccountRequest, email) {
            return _request('csChangeBankAccounts',
            changeBankAccountRequest
            ).then(Parser.toXml.bind(null,'csChangeBankAccountsResponse'))
        }
    },
    computed: {
        ...mapGetters(['getEmailId'])
    }   
}
</script>