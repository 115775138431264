import axios from 'axios'
import applicationConstants from '@/constants/AppConstants'
import AuthenticationService from '@/services/AuthenticationService.vue'
import utils from '@/utils/utils.vue'
export function _request (url, data) {
    const _data = data
      ? JSON.stringify({
        ClientAppId: applicationConstants.logInBodyRequest.ClientAppId,
        ClientAppVersion: applicationConstants.logInBodyRequest.ClientAppVersion,
        RequestId : utils.methods.getRandomNumber(),
        ...data
      })
      : JSON.stringify({
        ClientAppId: applicationConstants.logInBodyRequest.ClientAppId,
        ClientAppVersion: applicationConstants.logInBodyRequest.ClientAppVersion
      })
  
    return AuthenticationService.authUserValue ? axios.post(
      applicationConstants.publicServerBaseUrl + url,
      _data,
      {
        crossdomain: true,
        headers: {
          'Accept': '*/*',
          'Access-Control-Allow-Origin': '*',
          'Content-Type': 'application/json',
          common: {
            Authorization: AuthenticationService.authUserValue
          }
        }
      }
    )
      : null
  }
  