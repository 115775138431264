import AccountService from '@/services/AccountService'
import accountConstants from '@/constants/AccountConstants.vue'

export default {
    actions: {
        async fetchAccountDetails (ctx, email) {
            const accountDetails = await AccountService.methods.GetAccountDetails(email)
            ctx.commit(
                'updatedAccountDatails',
                accountDetails.csGetUserProfileResponse
            )
        },
        async fetchRecipientDetails (ctx, data) {
            const recipientDetails = await AccountService.methods.getRecipientDetails(data)
            ctx.commit(
                'updatedRecipientDatails',
                recipientDetails.csGetRecipientsResponse.RecipientItems.RecipientItem
            )
        },
        async fetchRemittanceOption (ctx) {
            const remittanceOption = await AccountService.methods.getRemittanceOptions() 
            ctx.commit(
                'updatedRemittanceOptionsDetails',
                remittanceOption.GetRemittanceOptionsResponse2.RemittanceOptions.RemittanceOption2
            )            
        },
        async reserveUseremail (ctx, useremail) {
            ctx.commit(
                'updateduseremail',
                useremail)
        }
    },
    mutations: {
        updatedAccountDatails(state, accountDetails) {
            try {
                let reducer = (accumulator, currentValue) => {
                    return (accumulator === currentValue?.Value) ? currentValue?.Text : accumulator
                }
                state.accountDetails = accountDetails 
                ?
                {
                    CustomerEmail: accountDetails.EmailAddress._text ?accountDetails.EmailAddress._text: '',
                    City : accountDetails.City._text ? accountDetails.City._text : '',
                    Country : accountDetails.Country._text ? accountDetails.Country._text : '',
                    DateOfBirth : accountDetails.DateOfBirth._text ? accountDetails.DateOfBirth._text: '',
                    EidStatus : accountConstants.eidStatusList.reduce(reducer,accountDetails.EidStatus._text ?accountDetails.EidStatus._text : '' ),
                    EidStatusList_forSupport :accountDetails.EidStatus._text ? accountDetails.EidStatus._text : '',
                    EmailAddress : accountDetails.EmailAddress._text ? accountDetails.EmailAddress._text : '',
                    EmailStatus : accountDetails.EmailStatus._text ? accountDetails.EmailStatus._text : '',
                    FirstName : accountDetails.FirstName._text ? accountDetails.FirstName._text : '',
                    InviteCode : accountDetails.InviteCode._text ? accountDetails.InviteCode._text : '',
                    IsEmailActivated : accountDetails.IsEmailActivated._text === '1' ? 'Yes' : 'No',
                    LastName : accountDetails.LastName._text ?accountDetails.LastName._text : '',
                    MiddleName : accountDetails.MiddleName._text ? accountDetails.MiddleName._text : '',
                    MobileNumber : accountDetails.MobileNumber._text ? accountDetails.MobileNumber._text.substring(2) : '',
                    MobileNumberVerified : accountDetails.MobileNumberVerified._text ? accountDetails.MobileNumberVerified._text : '',
                    MobileStatus : accountConstants.mobileStatuslistForSupport.reduce(reducer,accountDetails.MobileStatus._text ?accountDetails.MobileStatus._text : '' ),
                    MrmsStatus : accountConstants.mrmsStatusList.reduce(reducer,accountDetails.MrmsStatus._text ?accountDetails.MrmsStatus._text :'' ),
                    MrmsSupportStatus: accountDetails.MrmsStatus._text ? accountDetails.MrmsStatus._text : '',
                    MRMSSkipped: accountDetails.MRMSSkipped._text ?accountDetails.MRMSSkipped._text.toLowerCase() : '',
                    MRMSSkippedReason: accountDetails.MRMSSkippedReason._text ? accountDetails.MRMSSkippedReason._text : '',
                    PhoneNumber : accountDetails.PhoneNumber._text ? accountDetails.PhoneNumber._text.substring(2) : '',
                    PostalCode : accountDetails.PostalCode._text ? accountDetails.PostalCode._text : '',
                    State : accountDetails.State._text ? accountDetails.State._text : '',
                    StreetAddress1 : accountDetails.StreetAddress1._text ? accountDetails.StreetAddress1._text : '',
                    StreetAddress2 : accountDetails.StreetAddress2._text ? accountDetails.StreetAddress2._text : '',
                    TimeZone : accountDetails.TimeZone._text ? accountDetails.TimeZone._text : '',
                    UserIdNumber: accountDetails.UserIdNumber._text ? accountDetails.UserIdNumber._text : '',
                    UserRefId: accountDetails.UserRefId._text ? accountDetails.UserRefId._text: '',
                    UserIdType : accountDetails.UserIdType._text ? accountDetails.UserIdType._text : '',
                    UserProfileIssues : accountDetails.UserProfileIssues._text ? accountDetails.UserProfileIssues._text : '',
                    UserRoleId : accountDetails.UserRoleId._text ? accountDetails.UserRoleId._text : '',
                    AccountStatus : accountDetails.AccountStatus._text ? accountDetails.AccountStatus._text : '',
                    AccountSubStatus : accountDetails.AccountSubStatus._text ? accountDetails.AccountSubStatus._text : null,
                    EIDResult : accountDetails.EIDResult._text ? accountDetails.EIDResult._text : '',
                    EIDRunDate : accountDetails.EIDRunDate._text ? accountDetails.EIDRunDate._text : '',
                    EIDSkipped : accountDetails.EIDSkipped._text ? accountDetails.EIDSkipped._text.toLowerCase() : '',
                    AccouEIDSkippedReasonntStatus : accountDetails.EIDSkippedReason._text ? accountDetails.EIDSkippedReason._text : '',
                    EIDTransactionKey : accountDetails.EIDTransactionKey._text ? accountDetails.EIDTransactionKey._text: '',
                    IsMarketer : accountDetails.IsMarketer._text ? accountDetails.IsMarketer._text.toLowerCase() : '',
                    MobileNumberManualVerificationReason : accountDetails.MobileNumberManualVerificationReason._text ? accountDetails.MobileNumberManualVerificationReason._text : '',
                    MobilePhoneVerificationRequestID : accountDetails.MobilePhoneVerificationRequestID._text ? accountDetails.MobilePhoneVerificationRequestID._text : '',
                    MobilePhoneVerificationResultID : accountDetails.MobilePhoneVerificationResultID._text ? accountDetails.MobilePhoneVerificationResultID._text : '',
                    Notes: Object.keys(accountDetails.Notes).length > 0 ? Array.isArray(accountDetails.Notes.csConsumerAccountNote) ? accountDetails.Notes.csConsumerAccountNote.map(s => ({ CreateDateUtc: s.CreateDateUtc._text, CreatedBy: s.CreatedBy._text, Notes: s.Note._text })) : [{
                        CreateDateUtc : accountDetails.Notes.csConsumerAccountNote?.CreateDateUtc._text,
                        CreatedBy  : accountDetails.Notes.csConsumerAccountNote?.CreatedBy._text,
                        Notes  :  accountDetails.Notes.csConsumerAccountNote?.Note._text
                    }] : [],
                    customProperties: accountDetails.CustomProperties.NameValuePair ? Array.isArray(accountDetails.CustomProperties.NameValuePair) ? accountDetails.CustomProperties.NameValuePair.map(cp => ({ name: cp.Name._text, value: cp.Value._text })) : [accountDetails.CustomProperties.NameValuePair].map(cp => ({ name: cp.Name._text, value: cp.Value._text })) : [],
                    RiskScore : accountDetails.RiskScore._text,
                    SignupDate : accountDetails.SignupDate._text,
                    wfe: accountDetails.WFE._text ? accountDetails.WFE._text : null,
                    goldMemberShip: accountDetails.Gold._text ? accountDetails.Gold._text : null,
                    isAccountWhiteListed: accountDetails.WhitelistedForLimits._text ? accountDetails.WhitelistedForLimits._text : null,
                    ComplianceHistory: accountDetails.ComplianceHistory ? (Object.keys(accountDetails.ComplianceHistory).length > 0 ? Array.isArray(accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory) ? accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory.map(s => ({ ComplianceProcess: s.ComplianceProcess._text, ComplianceResult: s.ComplianceResult._text, CompliancedDate: s.CompliancedDate._text, CompliancedObject: s.CompliancedObject._text, CreatedBy: s.CreatedBy._text})) : [{
                        ComplianceProcess : accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory?.ComplianceProcess._text,
                        ComplianceResult  : accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory?.ComplianceResult._text,
                        CompliancedDate  :  accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory?.CompliancedDate._text,
                        CompliancedObject  : accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory?.CompliancedObject._text,
                        CreatedBy  :  accountDetails.ComplianceHistory.csConsumerAccountComplianceHistory?.CreatedBy._text
                    }] : []) : [],
                    IsGoodCustomer: accountDetails.IsGoodCustomer ? (accountDetails.IsGoodCustomer._text ? ((accountDetails.IsGoodCustomer._text).toUpperCase() === 'TRUE' ? true : false ) : false) : false,
                    SignUpDeviceIP: accountDetails.SignUpDeviceIP?._text ? accountDetails.SignUpDeviceIP._text : null    
                } : null 
            } catch (error) {              
                //
            }
        },
        updatedRecipientDatails (state, recipientDetails) {
            if(recipientDetails) {
                if(!Array.isArray(recipientDetails)) {
                    recipientDetails = [recipientDetails]
                }
                for (const recipientdata in recipientDetails) {
                    for(const item in recipientDetails[recipientdata]){
                        if({}.hasOwnProperty.call(recipientDetails[recipientdata][item],'NameValuePair')) {
                            for (const namedValuePair in recipientDetails[recipientdata][item]) {
                                if(Array.isArray(recipientDetails[recipientdata][item][namedValuePair])) {
                                    for (const namedvalue in recipientDetails[recipientdata][item][namedValuePair]) { 
                                        for (const pair in recipientDetails[recipientdata][item][namedValuePair][namedvalue]) {
                                            recipientDetails[recipientdata][item][namedValuePair][namedvalue][pair] = recipientDetails[recipientdata][item][namedValuePair][namedvalue][pair]?._text
                                        }
                                    }
                                } else {
                                   for (const item1 in recipientDetails[recipientdata][item][namedValuePair]) {
                                        for (const innerItem in recipientDetails[recipientdata][item][namedValuePair][item1]) {
                                            recipientDetails[recipientdata][item][namedValuePair][item1] = recipientDetails[recipientdata][item][namedValuePair][item1]._text
                                        }
                                   }
                                }                          
                            }
                        } else {
                            recipientDetails[recipientdata][item] = recipientDetails[recipientdata][item]._text                        
                        }
                    }
                } 
            }else{
                recipientDetails = []
            }        
            state.recipientDetails = recipientDetails                 
        },
        updatedRemittanceOptionsDetails (state, remittanceOptionDetails) {
            const mapRemittanceOption = (remittance) => {
                Object.values(remittance).map((el, key) => {
                    const objectkey = Object.keys(remittance)[key]
                    remittance[objectkey] = el._text
                })
            }
            remittanceOptionDetails.map(mapRemittanceOption)
            state.remittanceOption2 = remittanceOptionDetails
        },
        updateduseremail (state, useremail) {
            state.useremail = useremail
        }
    },
    getters: {
        getAccountDetails (state) {
            return state.accountDetails
        },
        getRecipientDetails (state) {
            return state.recipientDetails
        },
        getRemittanceOptions2 (state) {
            return state.remittanceOption2
        },
        getUseremail (state) {
            return  state.useremail
        }
    },
    state: {
        accountDetails : null,
        recipientDetails : null,
        remittanceOption2 : null,
        useremail : ''
    }
}