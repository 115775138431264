import Vue from 'vue'
import VueRouter from 'vue-router'
const Login = () => import('@/components/login/login')
const AmentAccount = () => import('@/components/amend/account2/account')
const DashBoard = () => import('@/components/dashboardpagination/dashboard')
const ViewAccount = () => import('@/components/amend/view/account')
const BankAccount = () => import('@/components/bank/bank')
const UserSummary = () => import('@/components/user/summary/summary')
const AmentRecipient = () => import('@/components/amend/recipient/recipient')
const AddPromotion = () => import('@/components/promotion/add/addpromotion')
const BulkPromotion = () => import('@/components/promotion/bulk/bulkpromotion')
const ViewPromotion = () => import('@/components/promotion/view/viewpromotion')
const Referral = () => import('@/components/referral/referral')
// const ResendActivationMail = () => import('@/components/email/resendemail/resendactivationmail')
const ViewProfile = () => import('@/components/user/profile/profile')
const ProcessEft = () => import('@/components/transaction/eft/process/process')
const EFTTransaction = () => import('@/components/transaction/eft/process/processeft/processeft')
const CompleteETransfer =() => import('@/components/transaction/etransfer/complete/complete')
const ReviewETransfer =() => import('@/components/transaction/etransfer/review/review')
const CompleteInteracPSIGate =() => import('@/components/transaction/interac/completeinteracpsigate/completeinteracpsigate')
const CompleteInteracMoneris =() => import('@/components/transaction/interac/completeinteracmoneris/completeinteracmoneris')
const ReviewMonerisTransaction =() => import('@/components/transaction/interac/completeinteracmoneris/reviewmoneristransaction/reviewmoneristransaction')
const EmailTransactionInvoice =() => import('@/components/transaction/emailtransactioninvoice/emailtransactioninvoice')
const ResendMoneyTransfer = () => import('@/components/transaction/sendmoney/resendmoneytransfer/resendmoneytransfer')
const SendMoneyForCustomer = () => import('@/components/transaction/sendmoney/sendmoneyforcustomer/sendmoneyforcustomer')
// const TransactionTrace = () => import('@/components/transaction/transactiontrace/transactiontrace')
const ViewTransactions = () => import('@/components/transaction/viewtransactions2/viewtransactions') 
const PrintTrnasaction = () => import('@/components/transaction/printtransaction2/printtransaction')
const FxTransactionSummary = () => import('@/components/fxoperation/fxtransactionsummary/fxtransactionsummary')
const FxRate = () => import('@/components/fxoperation/fxrate/fxrate')
const AddFxTransaction = () => import('@/components/fxoperation/addfxtransaction/addfxtransaction')
const FxTransaction = () => import('@/components/fxoperation/fxtransaction/fxtransaction') 
const FxAccount = () => import('@/components/fxoperation/fxaccount/fxaccount') 
const GetExchangeRate = () => import('@/components/fxoperation/getexchangerate/getexchangerate') 
const ChangePassword = () => import ('@/components/user/changepassword/changepassword')
const InitiateETransfer = () => import ('@/components/transaction/etransfer/initiate/initiate')
const CompleteEFT = () =>  import ('@/components/transaction/eft/complete/complete')
const InitiateEFT = () =>  import ('@/components/transaction/eft/initiate/initiateeft')
const TransactionReports =() => import ('@/components/reports/transactionreports/transactionreports')
const CompleteEFTTransaction =() => import('@/components/transaction/eft/complete/completeeft/completeeft')
const PrintPreview = () => import('@/components/transaction/printtransaction/print/print')
const Confirmation = () => import('@/components/transaction/confirmation/confirmation')
const SaltReview = () => import('@/components/transaction/interac/completeinteracpsigate/saltreview/saltreview')
const MenuRights = () => import('@/components/rights/menurights/menurights')
const ManageRights = () => import('@/components/rights/managerights/managerights')
const AccountAdjusment = () => import('@/components/accountadjustment/accountadjustment')
const CustomerReport = () => import('@/components/reports/customerreports/customerreports.vue')
const ReferralReport = () => import('@/components/reports/referralreports/referralreports.vue')
const TransactionStatusByDestinationCountry = () => import('@/components/reports/transactionreports/bydestinationcountry/bydestinationcountry.vue')
const PromoUsageReport = () => import('@/components/reports/promoreports/promousagereport.vue')
const TransactionStatusByDestinationCountryByDay = () => import('@/components/reports/transactionreports/bydestcountrybyday/bydestcountrybyday.vue')
const TransactionStatusByDevice = () => import('@/components/reports/transactionreports/bydevice/bydevice.vue')
const TransactionStatusByRemittanceGateway = () => import('@/components/reports/transactionreports/byremittancegateway/byremittancegateway.vue')
const UserReportByMonth = () => import('@/components/reports/userreports/useractivebymonth/useractivebymonth.vue')
const UserSignedUpByMonth = () => import('@/components/reports/userreports/usersignupbymonth/usersignupbymonth.vue')
const CustomerRecentLogin = () => import('@/components/reports/customerlogin/customerlogin.vue')
const PageNotFound = () => import('@/pages/notfound')
const FxOverrideWrite = () => import('@/components/fxoperation/fxoverridewrite/fxoverridewrite.vue')
const ManageFees = () => import('@/components/fxoperation/managefees/managefee.vue')


Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login
  },
  {
    path: '/amendccount',
    name: 'AmendAccount',
    component: AmentAccount,
    meta: {
      requiredAuth: true,
      rightsCode: 'AmendAccount',
      access: false
    }
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component : DashBoard,
    meta: {
      requiredAuth: true,
      rightsCode: 'ShowDashboard',
      access: false
    }
  },
  {
    path: '/usersummary',
    name: 'UserSummary',
    component : UserSummary,
    meta: {
      requiredAuth: true,
      rightsCode: 'UserSummary',
      access: false
    }
  },
  {
    path: '/bankaccount',
    name: 'BankAccount',
    component : BankAccount,
    meta: {
      requiredAuth: true,
      rightsCode: 'ShowBankAccounts',
      access: false
    }
  },
  {
    path: '/viewaccount',
    name: 'ViewAccount',
    component : ViewAccount,
    meta: {
      requiredAuth: true,
      rightsCode: 'ViewAccount',
      access: false
    }
  },
  {
    path: '/amendrecipient',
    name: 'AmendRecipient',
    component : AmentRecipient,
    meta: {
      requiredAuth: true,
      rightsCode: 'AmendRecipient',
      access: false
    }
  },
  {
    path: '/addpromotion',
    name: 'AddPromotion',
    component : AddPromotion,
    meta: {
      requiredAuth: true,
      rightsCode: 'AddPromotion',
      access: false
    }
  },
  {
    path: '/bulkpromotion',
    name: 'BulkPromotion',
    component : BulkPromotion,
    meta: {
      requiredAuth: true,
      rightsCode: 'BulkPromotion',
      access: false
    }
  },
  {
    path: '/viewpromotion',
    name: 'ViewPromotion',
    component : ViewPromotion,
    meta: {
      requiredAuth: true,
      rightsCode: 'ViewPromotions',
      access: false
    }
  },
  {
    path: '/Referral',
    name: 'Referral',
    component : Referral,
    meta: {
      requiredAuth: true,
      rightsCode: 'referral',
      access: false
    }
  },
  // {
  //   path: '/resendactivationmail',
  //   name: 'ResendActivationMail',
  //   component : ResendActivationMail,
  //   meta: {
  //     requiredAuth: true,
  //     rightsCode: 'ResendActivationMail',
  //     access: false
  //   }
  // },
  {
    path:'/processeft',
    name : 'ProcessEft',
    component :ProcessEft,
    meta : {
      requiredAuth : true,
      rightsCode: 'ProcessEFT',
      access: false
    }
  },
  {
    path:'/processeft',
    name :'EFTTransaction',
    component : EFTTransaction,
    meta : {
      requiredAuth : true,
      rightsCode: 'ProcessEFT',
      access: false
    }
  },
  {
    path :'/completeetransfer',
    name : 'CompleteETransfer',
    component : CompleteETransfer,
    meta : {
      requiredAuth : true,
      rightsCode: '',
      access: false
    }
  },
  {
    path:'/completeetransfer',
    name :'ReviewETransfer',
    component: ReviewETransfer,
    meta : {
      requiredAuth : true,
      rightsCode: '',
      access: false
    }
  },
  {
    path : '/completeinteracpsigate',
    name:'CompleteInteracPSIGate',
    component : CompleteInteracPSIGate,
    meta : {
      requiredAuth : true,
      rightsCode: 'CompleteSalt',
      access: false
    }
  },
  {
    path : '/completeinteracmoneris',
    name:'CompleteInteracMoneris',
    component: CompleteInteracMoneris,
    meta : {
      requiredAuth : true,
      rightsCode: 'Complete Moneris',
      access: false
    }
  },
  {
    path : '/completeinteracmoneris',
    name : 'ReviewMonerisTransaction',
    component: ReviewMonerisTransaction,
    meta : {
      requiredAuth: true,
      rightsCode: 'Complete Moneris',
      access: false
    }
  },
  {
    path : '/emailtransactioninvoice',
    name : 'EmailTransactionInvoice',
    component: EmailTransactionInvoice,
    meta : {
      requiredAuth: true,
      rightsCode: 'EmailInvoice',
      access: false
    }
  },
  {
    path : '/sendmoney',
    name: 'SendMoney',
    component : SendMoneyForCustomer,
    meta : {
      requiredAuth:true,
      rightsCode: 'SendMoneyforCustomer',
      access: false
    }
  },
  {
    path : '/resendmoneytransfer',
    name: 'ResendMoneyTransfer',
    component : ResendMoneyTransfer,
    meta : {
      requiredAuth:true,
      rightsCode: 'ReInitiateMoneyTransfer',
      access: false
    }
  },
  {
    path :'/sendmoney',
    name : 'SendMoneyForCustomer',
    component : SendMoneyForCustomer,
    meta : {
      requiredAuth : true,
      rightsCode: 'SendMoneyforCustomer',
      access: false
    }
  },
  // {
  //   path : '/transactiontrace',
  //   name : 'TransactionTrace',
  //   component: TransactionTrace,
  //   meta : {
  //     requiredAuth : true,
  //     rightsCode: 'TransactionTrace',
  //     access: false
  //   }
  // },
  {
    path : '/viewtransactions',
    name : 'ViewTransactions',
    component: ViewTransactions,
    meta : {
      requiredAuth : true,
      rightsCode: 'ViewTransactions',
      access: false
    }
  },
  {
    path : '/viewtransactions',
    name : 'PrintTransaction',
    component : PrintTrnasaction,
    meta : {
      requiredAuth : true,
      rightsCode: 'ViewTransactions',
      access: false
    }
  },
  {
    path : '/fxtransactionsummary',
    name : 'FXTrasactionSummary',
    component : FxTransactionSummary,
    meta : {
      requiredAuth : true,
      rightsCode: 'CSFXTransactionSummary',
      access: false
    }
  },
  {
    path : '/fxrate',
    name : 'FXRate',
    component : FxRate,
    meta : {
      requiredAuth : true,
      rightsCode: 'FXRate',
      access: false
    }
  },
  {
    path : '/addfxtransaction',
    name : 'AddFXTransationSummary',
    component : AddFxTransaction,
    meta : {
      requiredAuth : true,
      rightsCode: 'CSAddFXTransaction',
      access: false
    }
  },
  {
    path :'/fxtransactionsummary',
    name : 'FxTransaction',
    component : FxTransaction,
    meta : {
      requiredAuth : true,
      rightsCode: 'CSFXTransactionSummary',
      access: false
    }
  },
  {
    path : '/fxaccount',
    name : 'FxAccoount',
    component : FxAccount,
    meta : {
      requiredAuth : true,
      rightsCode: 'CSViewFXAccount',
      access: false
    }
  },
  {
    path : '/getexchangerate',
    name : 'GetExchangeRate',
    component : GetExchangeRate,
    meta : {
      requiredAuth : true,
      rightsCode: 'GetExchangeRate',
      access: false
    }
  },
  {
    path: '/changepassword',
    name : 'ChangePassword',
    component : ChangePassword,
    meta :{
      requiredAuth : true,
      rightsCode: '',
      access: true
    }
  },
  {
    path : '/viewprofile',
    name : 'ViewProfile',
    component : ViewProfile,
    meta : {
      requiredAuth : true,
      rightsCode: '',
      access: true
    }
  },
  {
    path: '/etransfer',
    name : 'ETransfer',
    component : InitiateETransfer,
    meta : {
      requiredAuth : true,
      rightsCode: 'InitiateETransfer',
      access: false
    }
  },
  {
    path: '/completeeft',
    name : 'CompleteEFT',
    component : CompleteEFT,
    meta :{
      requiredAuth : true,
      rightsCode: 'CompleteEFT',
      access: false
    }
  },
  {
    path: '/initiateeft',
    name : 'InitiateEFT',
    component : InitiateEFT,
    meta :{
      requiredAuth : true,
      rightsCode: 'InitiateEFT',
      access: false
    }
  },
  {
    path: '/transactionreports',
    name : 'TransactionReports',
    component : TransactionReports,
    meta :{
      requiredAuth : true,
      rightsCode: 'FullTransactionReport',
      access: false
    }
  },
  {
    path:'/completeeft',
    name :'CompleteEFTTransaction',
    component: CompleteEFTTransaction,
    meta : {
      requiredAuth : true,
      rightsCode: 'CompleteEFT',
      access: false
    }
  },
  {
    path:'/',
    name: 'PrintPreview',
    component: PrintPreview,
    meta : {
      requiredAuth : true,
      rightsCode: 'ViewTransactions',
      access: false
    }
  },
  {
    path:'/',
    name: 'Confirmation',
    component: Confirmation,
    meta : {
      requiredAuth : true,
      rightsCode: 'ETransferCompliance',
      access: false
    }
  },
  {
    path:'/saltreview',
    name: 'SaltReview',
    component: SaltReview,
    meta : {
      requiredAuth : true,
      rightsCode: 'CompleteSalt',
      access: false
    }
  },
  {
    path:'/menurights',
    name: 'MenuRights',
    component: MenuRights,
    meta : {
      requiredAuth : true,
      rightsCode: 'MenuRights',
      access: false
    }
  },
  {
    path:'/managerights',
    name: 'ManageRights',
    component: ManageRights,
    meta : {
      requiredAuth : true,
      rightsCode: 'ManageRights',
      access: false
    }
  },
  {
    path: '/accountadjustment',
    name: 'AccountAdjustment',
    component: AccountAdjusment,
    meta: {
      requiredAuth: true,
      rightsCode: 'AccountAdjustment',
      access: false
    }
  },
  {
    path: '/customerreports',
    name: 'CustomerReports',
    component: CustomerReport,
    meta: {
      requiredAuth: true,
      rightsCode: 'CustomerExtractReport',
      access: false
    }
  },
  {
    path: '/referralreport',
    name: 'ReferralReport',
    component: ReferralReport,
    meta: {
      requiredAuth: true,
      rightsCode: 'ReferralSummaryReport',
      access: false
    }
  },
  {
    path: '/transstatsbydestcountry',
    name: 'TransStatusByDestCountry',
    component: TransactionStatusByDestinationCountry,
    meta: {
      requiredAuth: true,
      rightsCode: 'TransStatsByDestCountry',
      access: false
    }
  },
  {
    path: '/transstatsbydestcountrybyday',
    name: 'TransStatusByDestCountryByDay',
    component: TransactionStatusByDestinationCountryByDay,
    meta: {
      requiredAuth: true,
      rightsCode: 'TransStatsByDestCountryByDay',
      access: false
    }
  },
  {
    path: '/transstatsbydevice',
    name: 'TransStatusByDevice',
    component: TransactionStatusByDevice,
    meta: {
      requiredAuth: true,
      rightsCode: 'TransStatsByDevice',
      access: false
    }
  },
  {
    path: '/transstatsbyremittancegateway',
    name: 'TransStatusByRemittanceGateway',
    component: TransactionStatusByRemittanceGateway,
    meta: {
      requiredAuth: true,
      rightsCode: 'TransStatsByRemittanceGateway',
      access: false
    }
  },
  {
    path: '/promoreports',
    name: 'PromoUsageReports',
    component: PromoUsageReport,
    meta: {
      requiredAuth: true,
      rightsCode: 'PromoReport',
      access: false
    }
  },
  {
    path: '/useractivebymonth',
    name: 'UserActiveByMonth',
    component: UserReportByMonth,
    meta: {
      requiredAuth: true,
      rightsCode: 'UsersActiveReportByMonth',
      access: false
    }
  },
  {
    path: '/usersignedupbymonth',
    name: 'UserSignedUpByMonth',
    component: UserSignedUpByMonth,
    meta: {
      requiredAuth: true,
      rightsCode: 'UserSignedUpReportByMonth',
      access: false
    }
  },
  {
    path: '/customerrecentlogins',
    name: 'CustomerRecentLogins',
    component: CustomerRecentLogin,
    meta: {
      requiredAuth: true,
      rightsCode: 'CustomerRecentLoginReport',
      access: false
    }
  },
  {
    path: '/fxoverridewrite',
    name: 'FxOverrideWrite',
    component: FxOverrideWrite,
    meta: {
      requiredAuth: true,
      rightsCode: 'FxOverrideWrite',
      access: false
    }
  },
  {
    path: '/managefees',
    name: 'ManageFees',
    component: ManageFees,
    meta: {
      requiredAuth: true,
      rightsCode: 'ManageFees',
      access: false
    }
  },
  {
    path:'/:notfound(.*)',  // always keep this as last router
    component: PageNotFound
  }
]
routes.forEach(route => {
  if(localStorage.getItem('userAclRights')) {
      if( (localStorage.getItem('userAclRights')).includes(route.meta?.rightsCode)) {
          route.meta.access = true
      } 
  }
})
const router = new VueRouter({
  mode: 'history',
  hash: false,
  routes
})
try {
  router.beforeEach((to, from, next) => {
    if (to.matched.some(route => route.meta.requiredAuth)) {
      if (localStorage.getItem('user-auth') && sessionStorage.getItem('vue-session-key') && to.matched.some(route => route.meta.access)) {
        next()
      } else if (localStorage.getItem('user-auth') && sessionStorage.getItem('vue-session-key') && !to.matched.some(route => route.meta.access)) {
        next(false)
      } else {
        next({name: 'Login'})
      }
    } else {
      if (localStorage.getItem('user-auth') && sessionStorage.getItem('vue-session-key')) {
        next()
      } else {
        if(to.name === 'Login') {
          next()
        } else {
          next(false)
        }
      }
    }
  })
} catch (err){
  //
}

export default router
