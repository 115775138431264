<template src="./sidebar.html">    
</template>
<script>
import Utils from '@/utils/utils'
export default {
  name: 'App',
  data () {
    return {
     menu: [      
        {
          title: 'Dashboard',
          icon: 'fa fa-dashboard',
          href: '/dashboard',
          hidden: !Utils.methods.checkUserACL('ShowDashboard')        
        },
        {
          title: 'Manage Accounts',
          icon: 'fa fa-users',
          hidden: !Utils.methods.checkUserACL('AmendAccount,ShowBankAccounts,AmendRecipient,ViewAccount,UserSummary'),
          child: [
            {
              title: 'Amend Account',
              href: '/amendccount',
              hidden: !Utils.methods.checkUserACL('AmendAccount')
            },
            {
              title: 'Bank Account',
              href: '/bankaccount',
              hidden: !Utils.methods.checkUserACL('ShowBankAccounts')       
            },
            {
              title: 'Amend Recipients',   
              href: '/amendrecipient' ,
              hidden: !Utils.methods.checkUserACL('AmendRecipient')        
            },
            {
              title: 'View Accounts',
              href: '/viewaccount',
              hidden: !Utils.methods.checkUserACL('ViewAccount')        

            },
            {
              title: 'User Summary',
              href: '/usersummary',
              hidden: !Utils.methods.checkUserACL('UserSummary')        

            },
            {
              title: 'Resend Activation Mail',
              href: '/resendactivationmail',
              hidden: true
            },
            {
              title: 'Account Adjustment',
              href: '/accountadjustment',
              hidden: !Utils.methods.checkUserACL('AccountAdjustment')        
            }, 
          ]
        },
        {
          title: 'Manage Transactions',
          icon: 'fa fa-university',
          hidden: !Utils.methods.checkUserACL('InitiateETransfer,ETransferCompliance,InitiateEFT,ProcessEFT,CompleteEFT,CompleteSalt,Complete Moneris,EmailInvoice,SendMoneyforCustomer,ReInitiateMoneyTransfer,ViewTransactions'),
          child: [
            {
              title: 'Initiate ETransfer',
              href : '/etransfer',
              hidden: !Utils.methods.checkUserACL('InitiateETransfer')        
            },
            {
              title: 'Complete E-Transafer',
              href : '/completeetransfer',
              hidden: !Utils.methods.checkUserACL('ETransferCompliance')      
            },
            {
              title : 'Initiate EFT',
              href : '/initiateeft',
              hidden: !Utils.methods.checkUserACL('InitiateEFT')        
            },
            {
              title: 'Process EFT',
              href : '/processeft',
              hidden: !Utils.methods.checkUserACL('ProcessEFT')        
            },{
              title : 'Complete EFT',
              href : '/completeeft',
              hidden: !Utils.methods.checkUserACL('CompleteEFT')        
            },            
            {
              title: 'Complete Interac(PSIGate)',
              href:'/completeinteracpsigate',
              hidden: !Utils.methods.checkUserACL('CompleteSalt')        
            },
            {
              title: 'Complete Interac(Monerics)', 
              href:'/completeinteracmoneris',
              hidden: !Utils.methods.checkUserACL('Complete Moneris')        
            },
            {
              title: 'Email Transaction Invoice',
              href:'/emailtransactioninvoice',
              hidden: !Utils.methods.checkUserACL('EmailInvoice')        
            },
            {
              title :'Send Money',
              href : '/sendmoney',
              hidden: !Utils.methods.checkUserACL('SendMoneyforCustomer')        
            },
            {
              title : 'Resend Money Transfer',
              href : '/resendmoneytransfer',
              hidden: !Utils.methods.checkUserACL('ReInitiateMoneyTransfer')        
            },
            {
              title : 'Transaction Trace',
              href : '/transactiontrace',
              hidden: true       
            },
            {
              title : 'View Transactions',
              href : '/viewtransactions',
              hidden: !Utils.methods.checkUserACL('ViewTransactions')        
            }
          ]
        },
        {
          title: 'Manage Promotions',
          icon: 'fa fa-gift',
          hidden: !Utils.methods.checkUserACL('AddPromotion,BulkPromotion,ViewPromotions'),
          child: [
            {
              title: 'Add Promotions',
              href : '/addpromotion',
              hidden: !Utils.methods.checkUserACL('AddPromotion')        
            },
            {
              title: 'Bulk Promotions',
              href : '/bulkpromotion',
              hidden : !Utils.methods.checkUserACL('BulkPromotion')
            },
            {
              title: 'View Promotions',
              href : '/viewpromotion',
              hidden: !Utils.methods.checkUserACL('ViewPromotions')        
            }            
          ]
        },
        {
          title: 'Gobal',
          icon: 'fa fa-globe',
          hidden : !Utils.methods.checkUserACL('referral,MenuRights,ManageRights'),
          child: [
            {
              title: 'Referral',
              href: '/referral',
              hidden: !Utils.methods.checkUserACL('referral')        
            },
            {
              title: 'Menu Rights',
              href: '/menurights',
              hidden: !Utils.methods.checkUserACL('MenuRights')        
            },
            {
              title: 'Manage Rights',
              href: '/managerights',
              hidden: !Utils.methods.checkUserACL('ManageRights')        
            }
          ]
        },
        {
          title: 'FX Operations',
          icon: 'fa fa-money',
          hidden : !Utils.methods.checkUserACL('FXRate,CSFXTransactionSummary,CSViewFXAccount,CSAddFXTransaction,GetExchangeRate'),
          child: [
            {
              title: 'Fx Rate',
              href : '/fxrate',
              hidden: !Utils.methods.checkUserACL('FXRate')        
            },
            {
              title:'Fx Transaction Summary',
              href : '/fxtransactionsummary',
              hidden: !Utils.methods.checkUserACL('CSFXTransactionSummary')        
            },
            {
              title :'FX Account',
              href : '/fxaccount',
              hidden: !Utils.methods.checkUserACL('CSViewFXAccount')        
            },
            {
              title: 'Add Fx Transaction',
              href: '/addfxtransaction',
              hidden: !Utils.methods.checkUserACL('CSAddFXTransaction')        
            },
            {
              title : 'Get Exchange Rate',
              href : '/getexchangerate',
              hidden: !Utils.methods.checkUserACL('GetExchangeRate')        
            },
            {
              title : 'FxOverride Write',
              href : '/fxoverridewrite',
              hidden: !Utils.methods.checkUserACL('FxOverrideWrite')    
            },
            {
              title : 'Manage Fee',
              href : '/managefees',
              hidden: !Utils.methods.checkUserACL('ManageFees')       
            }                 
          ]
        },
         {
          title: 'Reports',
          icon: 'fa fa-newspaper-o',
          hidden: !Utils.methods.checkUserACL('FullTransactionReport,CustomerExtractReport,CustomerRecentLoginReport,PromoReport,ReferralSummaryReport,TransStatsByDestCountry,TransStatsByDestCountryByDay,TransStatsByDevice,TransStatsByRemittanceGateway,UsersActiveReportByMonth,UserSignedUpReportByMonth'),
          child: [
            {
              title: 'Transaction Reports',
              href : '/transactionreports',
              hidden: !Utils.methods.checkUserACL('FullTransactionReport')        
            },
            {
              title: 'Customer Extract',
              href : '/customerreports',
              hidden: !Utils.methods.checkUserACL('CustomerExtractReport')        
            },
            {
              title: 'Customer Recent Login',
              href : '/customerrecentlogins',
              hidden: !Utils.methods.checkUserACL('CustomerRecentLoginReport')        
            },
            {
              title: 'Promo',
              href : '/promoreports',
              hidden: !Utils.methods.checkUserACL('PromoReport')        
            },
            {
              title: 'Referral Summary',
              href: '/referralreport',
              hidden: !Utils.methods.checkUserACL('ReferralSummaryReport')
            },
            {
              title: 'Transaction Statistics',
              hidden: !Utils.methods.checkUserACL('TransStatsByDestCountry,TransStatsByDestCountryByDay,TransStatsByDevice,TransStatsByRemittanceGateway'),
              child: [
                {
                  title: 'By Destination Country',
                  href: '/transstatsbydestcountry',
                  hidden: !Utils.methods.checkUserACL('TransStatsByDestCountry')
                },
                {
                  title: 'By Dest Country and Day',
                  href: '/transstatsbydestcountrybyday',
                  hidden: !Utils.methods.checkUserACL('TransStatsByDestCountryByDay')
                },
                {
                  title: 'By Device',
                  href: '/transstatsbydevice',
                  hidden: !Utils.methods.checkUserACL('TransStatsByDevice')
                },
                {
                  title: "By Remittance Gateway",
                  href: '/transstatsbyremittancegateway',
                  hidden: !Utils.methods.checkUserACL('TransStatsByRemittanceGateway')
                }
              ]
            },
            {
              title: 'User Report',
              hidden: !Utils.methods.checkUserACL('UsersActiveReportByMonth,UserSignedUpReportByMonth'),
              child: [
                {
                  title: 'Users Active By Month',
                  href: '/useractivebymonth',
                  hidden: !Utils.methods.checkUserACL('UsersActiveReportByMonth')
                },
                {
                  title: 'Users SignedUp By Month',
                  href: '/usersignedupbymonth',
                  hidden: !Utils.methods.checkUserACL('UserSignedUpReportByMonth')
                }
              ]
            }        
          ]
        },
      ],
      collapsed: false,
      themes: [
        {
          name: 'Default theme',
          input: ''
        },
        {
          name: 'White theme',
          input: 'white-theme'
        }
      ],    
      isOnMobile: false,
      sidebardwith: '350px'
    }
  },
  mounted () {
    this.onResize()
    window.addEventListener('resize', this.onResize)
  },
  methods: {
    onToggleCollapse (collapsed) {
      this.collapsed = collapsed
      this.$emit('toggle-collapse',collapsed)
    },
    onResize () {
      if (window.innerWidth <= 876) {
        this.isOnMobile = true
        this.collapsed = true
      } else {
        this.isOnMobile = false
        this.collapsed = false
      }
    }
  },
  props: {
    selectedTheme : {
      required : true
    }
  }
}

</script>