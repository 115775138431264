<script>
export default {
    provincesList : [{
        Provice: "Alberta",
        ProviceCode: "AB",
        TimeZone: "Mountain Standard Time"
    },
    {
        Provice: "British Columbia",
        ProviceCode: "BC",
        TimeZone: "Pacific Standard Time"
    },
    {
        Provice: "Manitoba",
        ProviceCode: "MB",
        TimeZone: "Central Standard Time"
    },
    {
        Provice: "New Brunswick",
        ProviceCode: "NB",
        TimeZone: "Eastern Standard Time"
    },
    {
        Provice: "Newfoundland and Labrador",
        ProviceCode: "NL",
        TimeZone: "Newfoundland Standard Time"
    },
    {
        Provice: "Nova Scotia",
        ProviceCode: "NS",
        TimeZone: "Atlantic Standard Time"
    },
    {
        Provice: "Ontario",
        ProviceCode: "ON",
        TimeZone: "Eastern Standard Time"
    },
    {
        Provice: "Prince Edward Island",
        ProviceCode: "PE",
        TimeZone: "Atlantic Standard Time"
    },
    {
        Provice: "Quebec",
        ProviceCode: "QC",
        TimeZone: "Eastern Standard Time"
    },
    {
        Provice: "Saskatchewan",
        ProviceCode: "SK",
        TimeZone: "Central Standard Time"
    },
    {
        Provice: "Northwest Territories",
        ProviceCode: "NT",
        TimeZone: "Mountain Standard Time"
    },
    {
        Provice: "Nunavut",
        ProviceCode: "NU",
        TimeZone: "Eastern Standard Time"
    },
    {
        Provice: "Yukon",
        ProviceCode: "YT",
        TimeZone: "Pacific Standard Time"
    }],
    accountSubStatus: [{
        Text: 'ByAutomatedProcess',
        Value: '1',
        AccountStatus: '-1'
    },
    {
        Text: 'Fraud',
        Value: '2',
        AccountStatus: '1'
    },
    {
        Text: 'NotSupported',
        Value: '3',
        AccountStatus: '1'
    },
    {
        Text: "Waiting for customer's response",
        Value: '4',
        AccountStatus: '0'
    },
    {
        Text: 'InActive',
        Value: '5',
        AccountStatus: '1'
    },
    {
        Text: 'Zero Transaction',
        Value: '6',
        AccountStatus: '1'
    }],
    accountStatus: [
    {
        Text: 'Not Activated',
        Value: '-1'
    },
    {
        Text: 'Enabled',
        Value: '0'        
    },
    {
        Text: 'Disabled',
        Value: '1'        
    },
    {
        Text: 'Closed',
        Value: '2'        
    },
    {
        Text: 'RequiresReview',
        Value: '3'
    },
    {
        Text: 'SignedUp',
        Value: '4'        
    }],
    riskScorceList: [{
        Text: 'Low',
        Value: '1',
        Color: 'white'
    },
    {
        Text: 'Medium',
        Value: '2',
        Color: 'yellow'
    },
    {
        Text: 'High',
        Value: '3',
        Color: 'red'
    }],
    emailStatusList: [{
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'AutoVerifed',
        Value: '1'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '2'        
    },
    {
        Text: 'NotVerifiedInactive',
        Value: '3'        
    }],
    emailStatusListForSupport: [{
        Text: 'unchanged',
        Value: '',
    },
    {
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'AutoVerifed',
        Value: '1'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '2'        
    },
    {
        Text: 'NotVerifiedInactive',
        Value: '3'        
    }],
    eidStatusList: [{
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'Passed',
        Value: '1'        
    },
    {
        Text: 'Review',
        Value: '2'        
    },
    {
        Text: 'Failed',
        Value: '3'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '4'        
    },
    {
        Text: 'UnknownOldUser',
        Value: '5'        
    },
    {
        Text: 'ManualRun',
        Value: '6'        
    }],
    mobileStatusList: [{
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'AutoVerifed',
        Value: '1'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '2'        
    }],
    mrmsStatusList: [{
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'Passed',
        Value: '1'        
    },
    {
        Text: 'Review',
        Value: '2'        
    },
    {
        Text: 'Failed',
        Value: '3'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '4'        
    },
    {
        Text: 'UnknownOldUser',
        Value: '5'        
    }],
    mrmsSupportStatusList: [{
        Text: 'unchanged',
        Value: ''        
    },{
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'Passed',
        Value: '1'        
    },
    {
        Text: 'Review',
        Value: '2'        
    },
    {
        Text: 'Failed',
        Value: '3'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '4'        
    },
    {
        Text: 'UnknownOldUser',
        Value: '5'        
    }],
    eidStatusList_forSupport: [{
        Text: 'unchanged',
        Value: ''        
    },
    {
        Text: 'NotVerifed',
        Value: '0'        
    },
    {
        Text: 'Passed',
        Value: '1'        
    },
    {
        Text: 'Review',
        Value: '2'        
    },
    {
        Text: 'Failed',
        Value: '3'        
    },
    {
        Text: 'ManuallyVerified',
        Value: '4'        
    },
    {
        Text: 'UnknownOldUser',
        Value: '5'        
    },
    {
        Text: 'ManualRun',
        Value: '6'        
    }],
    deviceOrigin: [
    {
        Text: 'ios',
        Value: '1'        
    },
    {
        Text: 'Android',
        Value: '2'        
    },
    {
        Text: 'WebClient',
        Value: '3'        
    },
    {
        Text: 'Unknown',
        Value: '4'
    }],
    eidCheck : [ {
        Text : 'Yes',
        Value : '1'
    },
    {
        Text : 'No',
        Value: '0'
    }],
    eidResult : [{
        Text : 'Y',
        Value : 'Y'
    },
    {
        Text : 'N',
        Value : 'N'
    },
    {
        Text : 'R',
        Value: 'R'
    }],
    eidSkipped : [{
        Text :'Yes',
        Value : 'Y'
    },
    {
        Text : 'No',
        Value : 'N'
    }],
    userIdType : [{
        Text : 'Driver’s License',
        Value : '1'  
    },
    {
        Text : 'Passport',
        Value : '2'
    }],
    countryList : [{
        Text :'Åland Islands',
        Value : 'Åland Islands' ,
        Currency : 'EUR',
        Code : 'AX',
        DialerCode : '+358'      
    },
    {
        Text :'Australia',
        Value : 'Australia',
        Currency : 'AUD',
        Code : 'AU',
        DialerCode : '+61'
    },
    {
        Text :'Austria',
        Value : 'Austria',
        Currency : 'EUR',
        Code : 'AT',
        DialerCode : '+43'
    },
    {
        Text :'Azores',
        Value : 'Azores',
        Currency : 'EUR',
        Code : 'PT',
        DialerCode : '+351'        
    },
    {
        Text :'Belgium',
        Value : 'Belgium',
        Currency : 'EUR',
        Code : 'BE',
        DialerCode : '+32'        
    },
    {
        Text :'Canary Islands',
        Value : 'Canary Islands',
        Currency : 'EUR',
        Code : 'IC',
        DialerCode : '+34'
    },
    {
        Text :'China',
        Value : 'China',
        Currency : 'CNY',
        Code : 'CN',
        DialerCode : '+86'
    },
    {
        Text :'Cyprus',
        Value : 'Cyprus',
        Currency : 'EUR',
        Code : 'CY',
        DialerCode : '+357'
    },
    {
        Text :'Czech Republic',
        Value : 'Czech Republic',
        Currency : 'CZK',
        Code : 'CZ',
        DialerCode : '+420'
    },
    {
        Text :'Denmark',
        Value : 'Denmark',
        Currency : 'DKK',
        Code : 'DK',
        DialerCode : '+45'
    },
    {
        Text :'Estonia',
        Value : 'Estonia',
        Currency : 'EUR',
        Code : 'EE',
        DialerCode : '+372'
    },
    {
        Text :'Finland',
        Value : 'Finland',
        Currency : 'EUR',
        Code : 'FI',
        DialerCode : '+358'
    },
    {
        Text :'France',
        Value : 'France',
        Currency : 'EUR',
        Code : 'FR',
        DialerCode : '+33'
    },
    {
        Text :'French Guiana',
        Value : 'French Guiana',
        Currency : 'EUR',
        Code : 'GF',
        DialerCode : '+594'
    },
    {
        Text :'Germany',
        Value : 'Germany',
        Currency : 'EUR',
        Code : 'DE',
        DialerCode : '+49'
    },
    {
        Text :'Greece',
        Value : 'Greece',
        Currency : 'EUR',
        Code : 'GR',
        DialerCode : '+30'
    },
    {
        Text :'Guadeloupe',
        Value : 'Guadeloupe',
        Currency : 'EUR',
        Code : 'GP',
        DialerCode : '+590'
    },
    {
        Text :'Hong Kong',
        Value : 'Hong Kong',
        Currency : 'HKD',
        Code : 'HK',
        DialerCode : '+852'
    },
    {
        Text :'Hungary',
        Value : 'Hungary',
        Currency : 'HUF',
        Code : 'HU',
        DialerCode : '+36'
    },
    {
        Text :'India',
        Value : 'India',
        Currency : 'INR',
        Code : 'IN',
        DialerCode : '+91'
    },
    {
        Text :'Ireland',
        Value : 'Ireland',
        Currency : 'EUR',
        Code : '',
        DialerCode : '+353'
    },
    {
        Text :'Italy',
        Value : 'Italy',
        Currency : 'EUR',
        Code : 'IE',
        DialerCode : '+39'
    },
    {
        Text :'Latvia',
        Value : 'Latvia',
        Currency : 'EUR',
        Code : 'LV',
        DialerCode : '+371'
    },
    {
        Text :'Lithuania',
        Value : 'Lithuania',
        Currency : 'EUR',
        Code : 'LT',
        DialerCode : '+370'
    },
    {
        Text :'Luxembourg',
        Value : 'Luxembourg',
        Currency : 'EUR',
        Code : 'LU',
        DialerCode : '+352'
    },
    {
        Text :'Madeira',
        Value : 'Madeira',
        Currency : 'EUR',
        Code : 'PT',
        DialerCode : '+291'
    },
    {
        Text :'Malta',
        Value : 'Malta',
        Currency : 'EUR',
        Code : 'MT',
        DialerCode : '+356'
    },
    {
        Text :'Martinique',
        Value : 'Martinique',
        Currency : 'EUR',
        Code : 'MQ',
        DialerCode : '+596'
    },
    {
        Text :'Mayotte',
        Value : 'Mayotte',
        Currency : 'EUR',
        Code : 'YT',
        DialerCode : '+262'
    },
    {
        Text :'Mexico',
        Value : 'Mexico',
        Currency : 'MXN',
        Code : 'MX',
        DialerCode : '+52'
    },
    {
        Text :'Monaco',
        Value : 'Monaco',
        Currency : 'EUR',
        Code : 'MC',
        DialerCode : '+377'
    },
    {
        Text :'Netherlands',
        Value : 'Netherlands',
        Currency : 'EUR',
        Code : 'NL',
        DialerCode : '+31'
    },
    {
        Text :'Norway',
        Value : 'Norway',
        Currency : 'NOK',
        Code : 'NO',
        DialerCode : '+47'        
    },
    {
        Text :'Pakistan',
        Value : 'Pakistan',
        Currency : 'PKR',
        Code : 'PK',
        DialerCode : '+92'
    },
    {
        Text :'Philippines',
        Value : 'Philippines',
        Currency : 'PHP',
        Code : 'PH',
        DialerCode : '+63'
    },
    {
        Text :'Poland',
        Value : 'Poland',
        Currency : 'PLN',
        Code : 'PL',
        DialerCode : '+48'
    },
    {
        Text :'Portugal',
        Value : 'Portugal',
        Currency : 'EUR',
        Code : 'PT',
        DialerCode : '+351'
    },
    {
        Text :'Réunion',
        Value : 'Réunion',
        Currency : 'EUR',
        Code : 'RE',
        DialerCode : '+262'
    },
    {
        Text :'Saint Barthélemy',
        Value : 'Saint Barthélemy',
        Currency : 'EUR',
        Code : 'BL',
        DialerCode : '+590'
    },
    {
        Text :'Saint Martin (French part)',
        Value : 'Saint Martin (French part)',
        Currency : 'EUR',
        Code : 'MF',
        DialerCode : '+590'
    },
    {
        Text :'Saint Pierre and Miquelon',
        Value : 'Saint Pierre and Miquelon',
        Currency : 'EUR',
        Code : 'PM',
        DialerCode : '+508'        
    },
    {
        Text :'San Marino',
        Value : 'San Marino',
        Currency : 'EUR',
        Code : 'SM',
        DialerCode : '+378'
    },
    {
        Text :'Singapore',
        Value : 'Singapore',
        Currency : 'SGD',
        Code : 'SG',
        DialerCode : '+65'
    },
    {
        Text :'Slovakia',
        Value : 'Slovakia',
        Currency : 'EUR',
        Code : 'SK',
        DialerCode : '+421'
    },
    {
        Text :'Slovenia',
        Value : 'Slovenia',
        Currency : 'EUR',
        Code : 'SI',
        DialerCode : '+386'
    },
    {
        Text :'Spain',
        Value : 'Spain',
        Currency : 'EUR',
        Code : 'ES',
        DialerCode : '+34'
    },
    {
        Text :'Sri Lanka',
        Value : 'Sri Lanka',
        Currency : 'LKR',
        Code : 'LK',
        DialerCode : '+94'
    },
    {
        Text :'Sweden',
        Value : 'Sweden',
        Currency : 'SEK',
        Code : 'SE',
        DialerCode : '+46'
    },    
    {
        Text :'United Kingdom',
        Value : 'United Kingdom',
        Currency : 'GBP',
        Code : 'GB',
        DialerCode : '+44'
    },    
    {
        Text :'United States',
        Value : 'United States',
        Currency : 'USD',
        Code : 'US',
        DialerCode : '+1'
    }],
    wfeFlagList: [
        {name: 'Existing', value:1},
        {name: 'New', value:2}
    ],
    goldMemberShipList: [
        {name: 'Default', value:1},
        {name: 'Join', value:2},
        {name: 'DoNotJoin', value:9}
    ],
    mobileStatuslistForSupport: [
        {Text: 'UnChanged', Value: ''},
        {Text: 'AutoVerified', Value: '1'},
        {Text: 'NotVerified', Value: '0'},
        {Text: 'Manually Verified', Value: '2'}
    ],
    remittanceGatewayId: [
        { Text: 'BDO Bank Account - Instant Transfer', Value: '1', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'All Other Banks Account - Same Day Transfer', Value: '2', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Cash Pickup at one of BDO branches', Value: '3', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Cash Pickup at M.Lhullier Kwarta Padala', Value: '4', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Cash Pickup at Cebuana Lhuillier', Value: '5', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'SMART Mobile Account Transfer - Instant Transfer', Value: '7', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Cash Pickup at Palawan Pawnshop', Value: '8', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Cash Pickup at SM Forex Counters', Value: '9', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'Globe', Value: '10', FXProvider: 'BDO', Currency: 'PHP' },
        { Text: 'India Bank Transfer', Value: '11', FXProvider: 'IndusInd', Currency: 'INR' },
        { Text: 'United Kingdom Bank Transfer', Value: '12', FXProvider: 'CurrencyCloud', Currency: 'GBP' },
        { Text: 'United States Bank Transfer', Value: '13', FXProvider: 'CurrencyCloud', Currency: 'USD' },
        { Text: 'Austria Bank Transfer', Value: '14', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Belgium Bank Transfer', Value: '15', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Cyprus Bank Transfer', Value: '16', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Estonia Bank Transfer', Value: '17', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Finland Bank Transfer', Value: '18', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'France Bank Transfer', Value: '19', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'French Guiana Bank Transfer', Value: '20', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Germany Bank Transfer', Value: '21', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Greece Bank Transfer', Value: '22', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Guadeloupe Bank Transfer', Value: '23', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Ireland Bank Transfer', Value: '24', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Italy Bank Transfer', Value: '25', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Latvia Bank Transfer', Value: '26', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Lithuania Bank Transfer', Value: '27', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Luxembourg Bank Transfer', Value: '28', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Malta Bank Transfer', Value: '29', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Martinique Bank Transfer', Value: '30', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Mayotte Bank Transfer', Value: '31', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Monaco Bank Transfer', Value: '32', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Netherlands Bank Transfer', Value: '33', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Portugal Bank Transfer', Value: '34', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Saint Pierre and Miquelon Bank Transfer', Value: '35', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'San Marino  Bank Transfer', Value: '36', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Slovakia Bank Transfer', Value: '37', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Slovenia Bank Transfer', Value: '38', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Spain Bank Transfer', Value: '39', FXProvider: 'CurrencyCloud', Currency: 'EUR' },
        { Text: 'Cargills Bank Account', Value: '40', FXProvider: 'Cargills', Currency: 'LKR' },
        { Text: 'Transfer to Bank Account', Value: '41', FXProvider: 'Cargills', Currency: 'LKR' },
        { Text: 'Cash Pickup at Cargills Food City or Branch', Value: '42', FXProvider: 'Cargills', Currency: 'LKR' },
        { Text: 'Cash Pickup at BOP Branch', Value: '43', FXProvider: 'BOP', Currency: 'PKR' },
        { Text: 'Bank Deposit to BOP Account', Value: '44', FXProvider: 'BOP', Currency: 'PKR' },
        { Text: 'Bank Deposit to non-BOP Account', Value: '45', FXProvider: 'BOP', Currency: 'PKR' },
        { Text: 'Cash Pickup at Muthoot Finance', Value: '46', FXProvider: 'RoyalExchange_Cash', Currency: 'INR' },
        { Text: 'RoyalExchange Bank Account', Value: '47', FXProvider: 'RoyalExchange_Cash', Currency: 'INR' },
        { Text: 'Union Pay', Value: '48', FXProvider: 'Tranglo_UnionPay', Currency: 'CNY' },
        { Text: 'Bank Deposit', Value: '49', FXProvider: 'Tranglo_UnionPay', Currency: 'CNY' },
        { Text: 'Singapore Bank Transfer', Value: '50', FXProvider: 'CurrencyCloud', Currency: 'SGD' },
        { Text: 'Australia Bank Transfer', Value: '51', FXProvider: 'CurrencyCloud', Currency: 'AUD' },
        { Text: 'Czech republic Bank Transfer', Value: '52', FXProvider: 'CurrencyCloud', Currency: 'CZK' },
        { Text: 'Hungary Bank Transfer', Value: '53', FXProvider: 'CurrencyCloud', Currency: 'HUF' },
        { Text: 'Poland Bank Transfer', Value: '54', FXProvider: 'CurrencyCloud', Currency: 'PLN' },
        { Text: 'Denmark Bank Transfer', Value: '55', FXProvider: 'CurrencyCloud', Currency: 'DKK' },
        { Text: 'Sweden Bank Transfer', Value: '56', FXProvider: 'CurrencyCloud', Currency: 'SEK' },
        { Text: 'Norway Bank Transfer', Value: '57', FXProvider: 'CurrencyCloud', Currency: 'NOK' },
        { Text: 'Hong Kong Bank Transfer', Value: '58', FXProvider: 'CurrencyCloud', Currency: 'HKD' },
        { Text: 'Mexico Bank Transfer', Value: '59', FXProvider: 'CurrencyCloud', Currency: 'MXN' },
        { Text: 'YesBank - Bank Transfer', Value: '60', FXProvider: 'YesBank', Currency: 'INR' },
    ],
    fundingSources: [
        {Name:"PsiGate" ,Value: 1},
        {Name:"Moneris" ,Value: 2},
        {Name:"E-Transfer" ,Value: 3},
        {Name: "EFT",Value: 4}
    ],
    transactionSourceTypes: [
        {Name:"INTERAC" ,Value: 1},
        {Name:"EFT" ,Value: 2},
    ],
    transactionDestinationTypes: [
        {Name: "RemittanceGateway", Value: 1}
    ]
}
</script>