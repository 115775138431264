import xmlUtils from '@/utils/utils.vue'
import appConstants from '@/constants/AppConstants.vue'

export default {
  toXml: (key, res) => {    
    const response = xmlUtils.methods.getParseObjectFromXml(res.data)
    response[key].ErrorDescription._text = appConstants.errorCodes[+response[key].ErrorCode._text]
    if ([1, 96].includes(+response[key].ErrorCode._text)) {
      return new Promise(resolve => resolve(response))
    } else {
      return new Promise((resolve, reject) => {  
        if (+response[key].ErrorCode?._text === 2000) {
          reject(response[key])
        } else if (Object.keys(response[key].ErrorDetails, 'ErrorDetail').length > 0) {
          let data = Array.isArray(response[key].ErrorDetails.ErrorDetail) ? response[key].ErrorDetails.ErrorDetail : [response[key].ErrorDetails.ErrorDetail]
          let errorObject = {}
          let errorArray = []
          data.forEach(item => {
            errorArray.push((item.PropertyName._text ? item.PropertyName._text : '' ) + ' : ' + (item.ReasonDescription._text ? item.ReasonDescription._text : '')) 
          })
          errorObject.errorCode = +response[key].ErrorCode._text
          errorObject.errorDescription = response[key].ErrorDescription._text
          errorObject.errorDetails = errorArray
          try{
            if(response[key].DataVerificationResult && response[key].DataVerificationResult?.RejectedPersonalDataDetails?.length > 0) {
              errorObject.dataVerificationDetails = response[key].DataVerificationResult.RejectedPersonalDataDetails
            }
          } catch {
            //
          }
          
          reject(errorObject)
        } else { 
          let errorObject = {}
          errorObject.errorCode = +response[key].ErrorCode._text
          errorObject.errorDescription = response[key].ErrorDescription._text
          errorObject.errorDetails = []
          try {
            if(response[key].DataVerificationResult && response[key].DataVerificationResult?.RejectedPersonalDataDetails?.length > 0) {
              errorObject.dataVerificationDetails = response[key].DataVerificationResult.RejectedPersonalDataDetails
            }
          }catch {
            //
          }
          reject(errorObject)
        }
      })
    }
  }
}