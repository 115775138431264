import ACLService from '@/services/ACLService'
export default {
    actions : {
        async fetchRightsList (ctx) {
            let rightsList =  await ACLService.methods.getRightsList()
            ctx.commit(
                'storeRightsList',rightsList.data
            )
        },
        async fetchGroupList (ctx) {
            let GroupList = await ACLService.methods.getGroupList()
            ctx.commit(
                'storeGroupList', GroupList.data
            )
        },
        async fetchGroupRightsList (ctx) {
            let GroupRightsList = await ACLService.methods.getGroupRightsList()
            ctx.commit(
                'storeGroupRightsList', GroupRightsList.data
            )
        },
        async fetchUserList (ctx) {
            let userList = await ACLService.methods.getUserList()
            ctx.commit(
                'storeUserList', userList.data
            )
        },
        async fetchUserGroupList (ctx) {
            let userGroupList = await ACLService.methods.getUserGroupList()
            ctx.commit(
                'storeUserGroupList', userGroupList.data
            )
        },
        async fetchUserAcl (ctx,userData) {
            let userDetails
            if(!userData.groupId) {
                userDetails = (await ACLService.methods.getUserDetails(userData.userEmail)).data
                userData.groupId = userDetails[0].GroupId
            }
            let GroupRightsList = (await ACLService.methods.getGroupRightsList()).data
            let userAclRights = []
            let items = GroupRightsList.filter(item => item.groupId === userData.groupId)
            items.forEach(item => userAclRights.push(item.rightCode))
            ctx.commit(
                'storeUserAclRights', userAclRights
            )
        },
        async fetchUserDetails (ctx, userEmail) {
            let userDetails = (await ACLService.methods.getUserDetails(userEmail)).data
            ctx.commit(
                'storeUserDetails', userDetails[0]
            )
        },
        async fetchGroupDetails (ctx, groupId) {
            let groupDetails = (await ACLService.methods.getGroupDetails(groupId)).data
            ctx.commit(
                'storeGroupDetails', groupDetails[0]
            )
        }
    },
    mutations: {
        storeRightsList (state, rightsList) {
            state.rightsList = rightsList
        },
        storeGroupList (state, groupList) {
            state.groupList = groupList
        },
        storeGroupRightsList (state, groupRightsList) {
            state.groupRightsList = groupRightsList
        },
        storeUserList (state, userList) {
            state.userList = userList
        },
        storeUserGroupList (state, userGroupList) {
            state.userGroupList = userGroupList
        },
        storeUserAclRights(state, userAclRights) {
            state.userAclRights = userAclRights
        },
        storeUserDetails (state, userDetails) {
            state.userDetails = userDetails
        },
        storeGroupDetails (state, groupDetails) {
            state.groupDetails = groupDetails
        },
        storeEnabledRoutes (state, enabledRoutesArray) {
            state.enabledRoutes = enabledRoutesArray
        }
    },
    getters: {
        getRightsList (state) {
            return state.rightsList
        },
        getGroupList (state) {
            return state.groupList
        },
        getGroupRightsList (state) {
            return state.groupRightsList
        },
        getUserList (state) {
            return state.userList
        },
        getUserGroupList (state) {
            return state.userGroupList
        },
        getUserAclRights (state) {
            return state.userAclRights
        },
        getUserDetails (state) {
            return state.userDetails
        },
        getGroupDetails (state) {
            return state.groupDetails
        },
        getEnabledRoutes(state) {
            return state.enabledRoutes
        },
        getEnabledRoutesName(state) {
            let enabledRouteNames = []
            state.enabledRoutes.forEach(route=> enabledRouteNames.push(route.name))
            return enabledRouteNames 
        },
        getNextRoute(state) {
            let enabledRouteNames = []
            state.enabledRoutes.forEach(route=> enabledRouteNames.push(route.name))
            return ['Dashboard', 'AmendAccount', 'BankAccount', 'AmendRecipient', 'ViewAccount', 'UserSummary', 
            'ETransfer', 'CompleteETransfer', 'InitiateEFT', 'ProcessEft', 'CompleteEFT', 'CompleteInteracPSIGate', 'CompleteInteracMoneris', 'EmailTransactionInvoice', 'SendMoneyForCustomer', 'ResendMoneyTransfer', 'ViewTransactions',
            'AddPromotion', 'BulkPromotion', 'ViewPromotion',
            'Referral', 'MenuRights', 'ManageRights',
            'FXRate', 'FxTransaction', 'FxAccoount', 'AddFXTransationSummary', 'GetExchangeRate', 
            'TransactionReports']
            .find(routeName => enabledRouteNames.includes(routeName)) ?? 'ViewProfile'
        }
    },
    state: {
        rightsList : [],
        groupList : [],
        groupRightsList : [],
        userList : [],
        userGroupList : [],
        userAclRights : [],
        userDetails: [],
        groupDetails: [],
        enabledRoutes: [],
    }
}