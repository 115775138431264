<script>
import convert from 'xml-js'
import TransactionService from '@/services/TransactionService'
import moment from 'moment'

export default {
    name: 'utils',
    methods: {
        getParseObjectFromXml (xml) {
            const json = convert.xml2json(xml, {compact : true})
            return JSON.parse(json)
        },
        getRandomNumber(){
            const num = (Math.floor(Math.random() * 10000) + 10000).toString().substring(1)
            return num            
        },
        async calculateTransationFee (data, promoCode = null, userEmail =  null) {
            //in future whitelist needed to check 
            let exchangeRate = ''
            let fee = ''
            let sourceAmt = ''
            let destAmt = ''
            let error = {
                Description : null,
                ErrorCode : null
            }
            await TransactionService.methods.CalculateTransactionFee(data)             
            .then( async res => {
                try {                    
                    let response = res.CalculateTransactionFeeResponse
                    exchangeRate = Number.parseFloat((1 / response.ExchangeRate._text)).toPrecision(5)
                    fee = response.XferFee._text
                    sourceAmt = response.TotalCharge._text
                    destAmt = response.SendAmountDest._text
                } catch (error) {  
                    //
                }
                if (promoCode) {
                    let promo = promoCode.split('-')
                    await  TransactionService.methods.RedeemPromoCode({
                        'Amount' : data.SendAmountOrigin,
                        'Code' : promo[0],
                        'IssueNumber' : promo[1],
                        'RemittanceGateway' : data.RemittanceGatewayId,
                        'ValidateOnly' : true,
                        'CustomerEmail' : userEmail
                    }).then(ress => {
                        let promoAmount = Number(ress.csRedeemPromoCodeResponse.Nomination._text).toFixed(2)
                        sourceAmt = sourceAmt - promoAmount
                        if(promoAmount > fee){
                            //Custom Error Description for 
                        }
                        fee = Number(fee - promoAmount).toFixed(2)
                    })
                    .catch(err => { 
                        if(err?.ErrorCode?._text === "2000") {
                            error.Description = err?.ErrorDescription._text
                            error.ErrorCode = err?.ErrorCode?._text
                            let promoAmount = Number(err.Nomination._text).toFixed(2)
                            sourceAmt = sourceAmt - promoAmount
                            if(promoAmount > fee){
                                //Custom Error Description for 
                            }
                            fee = Number(fee - promoAmount).toFixed(2)
                        }else {
                            error.Description = err 
                        }             
                    })
                }
                
            })
            .catch(err => {
                //
            }) 
            return {'ExchangeRate' : exchangeRate, 'Fee': fee, 'SourceAmount' : sourceAmt, 'DestAmount' : destAmt,'Error' : error.Description,'ErrorCode':error.ErrorCode }
        },
        checkUserACL(rightsList) {
            let hasAccess = false
            let userRightsList = JSON.parse(localStorage.getItem('userAclRights'))
            let rightsListItem = rightsList.split(',')
            for (let i = 0 ;i< rightsListItem.length ; i++ ) {
                if(userRightsList.includes(rightsListItem[i])) {
                    hasAccess = true
                    break
                }
            }
            return hasAccess
        },
        //Generate UUID
        uuidv4() {
            return ([1e7]+-1e3+-4e3+-8e3+-1e11).replace(/[018]/g, c =>
                (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16).toUpperCase()
            );
        },
        UTCtoLocal (utcTime) {
            return utcTime? moment.utc(utcTime, 'MM/DD/YYYY HH:mm:ss').local().format("MM/DD/YYYY hh:mm:ss A"): null
        },
        LocalToUTC (localTime) {
            return localTime ? moment(localTime, 'MM-DD-YYYY HH:mm:ss').utc().format("MM/DD/YYYY HH:mm:ss") : null
        }
    }
}
</script>