<script>
import axios from "axios"
import AppConstants from '@/constants/AppConstants.vue'

export default {
   name : 'ACLService',
   methods: {
      //rights
       async getRightsList() {
          return await axios.get(AppConstants.JsonServerUrl.permission+"Rights")
       },
       //groups
       async getGroupList() {
          return await axios.get(AppConstants.JsonServerUrl.permission+"Groups")   
       },
       async addGroup(data) {
          return await axios.post(AppConstants.JsonServerUrl.permission+"Groups",data)  
       },
       async updateGroup(data) {
          return await axios.patch(AppConstants.JsonServerUrl.permission+"Groups/"+data.id, data)
       },
       async getGroupDetails (groupId) {
         return await axios.get(AppConstants.JsonServerUrl.permission+"Groups?GroupId="+groupId)
       },
       //group rights
       async getGroupRightsList() {
          return await axios.get(AppConstants.JsonServerUrl.privilege)   
       },
       async createGroupRightsItem(data) {
          return await axios.post(AppConstants.JsonServerUrl.privilege, data)
       },
       async deleteGroupRights(data) {
         try{
            return await axios.delete(AppConstants.JsonServerUrl.privilege+data.id)
         } catch (er) {
            //
         }
       },
       //users
       async getUserDetails (userEmail) {
         return await axios.get(AppConstants.JsonServerUrl.permission+"Users?UserEmail="+ userEmail)
       },
       async getUserList() {
          return await axios.get(AppConstants.JsonServerUrl.permission+"Users")
       },
       async updateUser (data) {
          return await axios.patch(AppConstants.JsonServerUrl.permission+"Users/"+data.id, data)
       },
       async addUser (data) {
          return await axios.post(AppConstants.JsonServerUrl.permission+"Users", data)
       },
       async deleteUser(data) {
          return await axios.patch(AppConstants.JsonServerUrl.permission+"Users/"+data.id, data)
       }, 
       //UserGroups
       async getUserGroupList () {
          return await axios.get(AppConstants.JsonServerUrl.permission+"UserGroups")
       },
       async updateUserGroup (data) {
          return await axios.patch(AppConstants.JsonServerUrl.permission+"UserGroups/"+data.id, data)
       },
       async addUserGroup (data) {
          return await axios.post(AppConstants.JsonServerUrl.permission+"UserGroups", data)
       }

   }
}
</script>
