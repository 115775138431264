<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'
export default {
    name: 'TransactionService',
    methods : {
        GetTransactionSummary(data) {
            return _request('csGetTransactionSummary',data).then(Parser.toXml.bind(null,'csTransactionSummaryResponse'))
        },
        GetTransactionDeatils(data) {
            return _request('csGetTransactionDetails',data).then(Parser.toXml.bind(null,'csTransactionDetailsResponse'))
        },
        AddTransactionNotes(notes,ReferenceNumber) {
            return _request('csAddTransactionNote',{'NoteText' : notes,'ReferenceNumber': ReferenceNumber}).then(Parser.toXml.bind(null,'csAddTransactionNoteResponse'))
        },
        CompleteEFTTransaction(data) {
            return _request('csCompleteEFTTransaction',data).then(Parser.toXml.bind(null,'csEFTCompleteTransactionDataResponse'))
        },
        CancelEFTTransaction(Email,Refnumber) {
            return _request('csCancelEFT',{'ReferenceNumber':Refnumber,'CustomerEmail':Email}).then(Parser.toXml.bind(null,'csEFTCancelTransactionDataResponse'))
        },
        CancelETransfer(customerEmail,Refnumber,Transactionid) {
            return _request('csCancelEtransfer', {'ReferenceNumber': Refnumber,'CustomerEmail':customerEmail,'TrnsactionId': Transactionid}).then(Parser.toXml.bind(null,'csEtransferCancelResponse'))
        },
        CompleteETransfer(data) {
            return _request('csCompleteEtransfer',data).then(Parser.toXml.bind(null,'csEtransferCompleteResponse'))
        },
        CancelOpTransaction(data) {
            return _request('csCancelIOPTransaction',data).then(Parser.toXml.bind(null,'csCancelIOPTransactionResponse'))
        },
        GetRecipientsDetails(customerEmail,recipientId,country) {
            return _request('csGetRecipients',{'CustomerEmail':customerEmail,'RecipientID':recipientId,'Country': country}).then(Parser.toXml.bind(null,'csGetRecipientsResponse'))
        },
        CompleteMonerisTransaction(data) {
            return _request('csCompleteMonerisIOPTransaction',data).then(Parser.toXml.bind(null,'csCompleteMonerisIOPTransactionResponse'))
        },
        CalculateTransactionFee(data) {
            return _request('CalculateTransactionFee',data).then(Parser.toXml.bind(null,'CalculateTransactionFeeResponse'))
        },
        SendMoney(data) {
            return _request('csSendMoney',data).then(Parser.toXml.bind(null,'csSendMoneyResponse'))
        },
        RedeemPromoCode(data) {
            return _request('csRedeemPromoCode',data).then(Parser.toXml.bind(null,'csRedeemPromoCodeResponse'))
        },
        ProcessEFT(data) {
            return _request('csProcessEFT',data).then(Parser.toXml.bind(null,'csEFTProcessTransactionDataResponse'))
        },
        InitiateEFT(data) {
             return _request('csInitiateEFT',data).then(Parser.toXml.bind(null,'csEFTInitTransactionDataResponse'))
        },
        initiateEtransfer (data) {
            return _request('csInitiateEtransfer', data).then(Parser.toXml.bind(null,'csEtransferInitResponse'))
        },
        CompleteSaltTransaction(data) {
            return _request('csCompleteSaltTransaction',data).then(Parser.toXml.bind(null,'csSaltCompleteTransactionDataResponse'))
        },
        sendEmailInstructions(data) {
            return _request('EmailEtransferInstruction', data).then(Parser.toXml.bind(null, 'EmailETransferInstructionResponse'))
        },
        updateEFT(data){
            return _request('csUpdateEFT', data).then(Parser.toXml.bind(null, 'csEFTUpdateResponse'))
        }
        
    }
}
</script>