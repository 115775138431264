<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'

export default {
    name : 'Promotionservice',
    methods : {
        UpdatePromotionDetails (data) {
             return _request('csManagePromoCodes', data).then(Parser.toXml.bind(null,'csManagePromoCodeResponse'))
        },
        GetPromoDetails (data) {
            return _request ('csGetPromoCodes', data).then(Parser.toXml.bind(null,'csGetPromoCodesResponse'))
        },
        GetPromoCodeUsageSummary (data) {
            return _request('csGetPromoCodesUsageSummary', data).then(Parser.toXml.bind(null, 'csGetPromoCodesUsageSummaryResponse'))
        }
    }
}
</script>