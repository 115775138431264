<script>
import { _request } from '@/helpers/_request.js'
import Parser from '@/helpers/_responseParser'
import moment from 'moment'
export default {
    name: 'AccountService',
    methods : {
        GetAccountDetails (email) {
            return _request('csGetUserProfile', { 'CustomerEmail': email }).then(Parser.toXml.bind(null,'csGetUserProfileResponse'))
        },
        UpdateUserProfileNotes (note, customerEmail){
            return _request('csAddUserProfileNote',{'CustomerEmail': customerEmail, 'Note': note}).then(Parser.toXml.bind(null,'csAddUserProfileNoteResponse'))
        },
        ResendEmailLink(UserInfo) {
             return _request('csUpdateUserProfile2', 
             UserInfo
             ).then(Parser.toXml.bind(null,'csUpdateUserProfileResponse2'))   
        },
        FetchUserSummary (usersummary) {
           return _request('csGetUsersSummary',
            {
               'AccountBalanceGreaterThan' :usersummary.accbalance ? usersummary.accbalance : null ,
               'AccountStatus':usersummary.accountStatus ? usersummary.accountStatus : null,
               'AccountSubStatus': usersummary.subStatus ? usersummary.subStatus: null,
               'EidCheckRun': usersummary.eidCheck ? usersummary.eidCheck : null,
               'EidStatus' : usersummary.eidStatus ? usersummary.eidStatus : null,
               'EmailStatus' : usersummary.emailStatus ? usersummary.emailStatus : null,
               'FirstName' : usersummary.firstName ? usersummary.firstName : null,
               'LastName' : usersummary.lastName ? usersummary.lastName : null,
               'MobileStatus' :usersummary.mobileStatus ? usersummary.mobileStatus : null,
               'MrmsStatus' : usersummary.mrmsStatus ? usersummary.mrmsStatus : null,
               'SignUpDeviceOrigin' : usersummary.deviceType ? usersummary.deviceType :null
            }
           ).then(Parser.toXml.bind(null,'csGetUsersSummaryResponse'))
        },
        GetAccountBalance(email) {
            return _request('csGetAccountBalance', { 'CustomerEmail': email }).then(Parser.toXml.bind(null,'csAccountBalanceResponse')) 
        },
        UpdateUserProfile(userCredential){
            return _request('csUpdateUserProfile2', 
            {
                'CustomerEmail': userCredential.CustomerEmail,
                'NewAccountStatus' : userCredential.AccountStatus,
                'SkipEquifaxVerification': userCredential.SkipEquifaxVerification ? '1' : '0',
                'SkipMrmsVerification': userCredential.NewMRMSSkipped ? '1' : '0',
                'MrmsSkippingReason': userCredential.MRMSSkippedReason,     
                'EquifaxSkippingReason' : userCredential.AccouEIDSkippedReasonntStatus,
                'NewAccountSubStatus': userCredential.AccountSubStatus,
                'NewRiskScore': userCredential.RiskScore,
                'MobileNumberManualVerificationReason' : userCredential.MobileNumberManualVerificationReason,
                'IsMarketer': userCredential.IsMarketer,
                'AccountWhiteListed': userCredential.isAccountWhiteListed,
                'EmailStatus': userCredential.EmailStatus,
                'MobileStatus': userCredential.MobileStatusSupport,
                'GoodCustomer': userCredential.IsGoodCustomer,
                'UserProfile' : {
                    'City': userCredential.City,
                    'Country': userCredential.Country,                   
                    'EmailAddress': userCredential.EmailAddress,
                    'FirstName': userCredential.FirstName,
                    'LastName': userCredential.LastName,
                    'MiddleName': userCredential.MiddleName,
                    'MobileNumber': userCredential.MobileNumber && userCredential.MobileNumber !== '' ? '+1' + userCredential.MobileNumber.replace(/[^\w\s]/gi, '') : null,
                    'PhoneNumber': userCredential.PhoneNumber && userCredential.PhoneNumber !== '' ? '+1' + userCredential.PhoneNumber.replace(/[^\w\s]/gi, '') : null,
                    'PostalCode': userCredential.PostalCode,
                    'State': userCredential.State,
                    'StreetAddress1': userCredential.StreetAddress1,
                    'StreetAddress2': userCredential.StreetAddress2,
                    'DateOfBirth':userCredential.DateOfBirth && userCredential.DateOfBirth !== '' ? moment(moment(userCredential.DateOfBirth)._d).format('YYYY/MM/DD') : null,
                    'TimeZone': userCredential.TimeZone,
                    'WFE': userCredential.wfe,
                    'Gold': userCredential.goldMemberShip,
                    'CustomProperties' : [ {
                        Name: 'UserIdType',
                        Value: userCredential.idType ? userCredential.idType : userCredential.UserIdType ? userCredential.UserIdType : userCredential.UserIdTypeId ? userCredential.UserIdTypeId : null 
                        },
                        {
                        Name: 'UserIdNumber',
                        Value: userCredential.idNumber || userCredential.UserIdNumber
                        },
                        {
                        Name: 'sOccupation',
                        Value: userCredential.sOccupation
                        },
                        {
                        Name: 'sIdExpiry',
                        Value: userCredential.sIdExpiry ? typeof userCredential.sIdExpiry === 'object' ? ![userCredential.sIdExpiry.day, userCredential.sIdExpiry.month, userCredential.sIdExpiry.year].includes('Invalid date') ? `${userCredential.sIdExpiry.month}/${userCredential.sIdExpiry.day}/${userCredential.sIdExpiry.year}` : '' : userCredential.sIdExpiry : ''
                        },
                        {
                        Name: 'SenderPlaceofBirth',
                        Value: userCredential.SenderPlaceofBirth
                    }].filter(customprop => customprop.Value)
                    // AccountWhiteListed : usercredentials.AccountWhiteListed,                  
                    // EidStatus : userCredential.EidStatus,
                    // EmailStatus: userCredential.EmailStatus,    
                    // MobileNumberVerifiedManually: userCredential.MobileNumberVerifiedManually,
                    // MobileStatus: userCredential.MobileStatus,
                    // MrmsStatus : userCredential.MrmsStatus,
                    // Notes: ''
                 
                },
                // 'EmailStatus' : userCredential.EmailStatus,
                // 'MobileStatus': userCredential.MobileStatus,
                // 'EidStatus' : userCredential.EidStatus,
                // 'MrmsStatus': userCredential.MrmsStatus,
                'MobileNumberVerifiedManually' : userCredential.MobileNumberVerifiedManually  === 2 ? 'True' : 'False'

                
            }).then(Parser.toXml.bind(null, 'csUpdateUserProfileResponse2'))
        },
        getRecipientDetails (data) {
            return _request('csGetRecipients', { 'CustomerEmail' : data.CustomerEmail , 'Country' : data.Country }).then(Parser.toXml.bind(null,'csGetRecipientsResponse'))
        },
        getRemittanceOptions () {
            return _request('GetRemittanceOptions2','').then(Parser.toXml.bind(null,'GetRemittanceOptionsResponse2'))            
        },
        getRecipientFields (remittanceId) {
            return _request('GetRecipientFields',{RemittanceOptionId : remittanceId }).then(Parser.toXml.bind(null,'GetRecipientFieldsResponse'))
        },
        getBankList (remittanceOptionId) {
            return _request('GetBankList', {'RemittanceOptionId' : remittanceOptionId}).then(Parser.toXml.bind(null,'GetBankListResponse'))
        },
        updateRecipientDetails (data) {  
          return _request('csChangeRecipient', data).then(Parser.toXml.bind(null,'csChangeRecipientResponse'))
        },
        addAccountAdjustment(data) {
            return _request('csAddAccountAdjustment', data).then(Parser.toXml.bind(null,'csAddAccountAdjustmentResponse'))
        }
    }
}
</script>