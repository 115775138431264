import BankService from '@/services/BankService'

export default {
    actions: {       
        async fetchBankDetails(ctx, email) {
            const bankDetails = await BankService.methods.GetBankDetails(email)
            ctx.commit(
                'updatedBankDetails',
                bankDetails.csGetBankAccountsResponse?.BankAccountsItems?.BankAccountsItem  
            )
        },
        setEmailId (ctx, email) {
            ctx.commit('updateEmailId', email)
        }
    },
    mutations: {
        updatedBankDetails(state, bankDetails) {
            bankDetails = bankDetails ? Array.isArray(bankDetails) ? bankDetails : [bankDetails] : []
            const mapBank = (bank) => {
                Object.values(bank).map((el,key) => {
                    const objectKey = Object.keys(bank)[key]
                    if (objectKey === 'SupplementalAccountData') {
                        bank[objectKey] = {}
                        Object.values(el).map((elChild,keyChild) => {
                            const childObjectkey = Object.keys(el)[keyChild]
                            bank[objectKey][childObjectkey] = {}
                            Object.values(elChild).map((elChildran, keyChildran) => {
                                const childranObjectkey = Object.keys(elChild)[keyChildran]                                
                                bank[objectKey][childObjectkey][childranObjectkey] = {}
                                bank[objectKey][childObjectkey][childranObjectkey] = elChildran._text ? elChildran._text : null
                            })
                        }) 
                    } else {
                        bank[objectKey] = el._text 
                    }                 
                })
            }
            bankDetails.map(mapBank)  
            state.bankDetails = bankDetails
        },
        updateEmailId(state, email) {
            state.emailId =  email
        }            
    },
    getters: {
        getBankDetails (state) {
            return state.bankDetails
        },
        getEmailId (state) {
            return state.emailId
        }
    },
    state: {
        bankDetails : null,
        emailId : ''
    }
}