import Vue from 'vue'
import App from './components/main/App'
import router from './router'
import store from './store'
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue'
import Vuelidate from 'vuelidate'
import VueSession from 'vue-session'
import VueLoading from 'vue-loading-overlay'
import VueSidebarMenu from 'vue-sidebar-menu'
import VueTables from 'vue-tables-2'
import VueHtmlToPaper from 'vue-html-to-paper'

// Import Bootstrap
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'vue-loading-overlay/dist/vue-loading.css';
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'

//Import AppConstants
import AppConstants from '@/constants/AppConstants.vue'

Vue.use(VueLoading)
Vue.use(BootstrapVue)
Vue.use(IconsPlugin)
Vue.use(Vuelidate)
Vue.use(VueSession)
Vue.use(VueSidebarMenu)
Vue.use(VueTables.ClientTable, {
  pagination: {
    show: !AppConstants.enableLazyLoading
  },
  filterable: AppConstants.enableLazyLoading ? false: []
})


Vue.config.productionTip = false

const options = {
  name: '_blank',
  specs: [
    'fullscreen=yes',
    'titlebar=yes',
    'scrollbars=yes'
  ],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    `${window.location.origin}/myPrint.css`
  ],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: window.document.title, // override the window title
}

Vue.use(VueHtmlToPaper, options)

const EventBus = new Vue()

/* eslint-disable no-new */
Object.filter = (obj, predicate) =>
  Object.fromEntries(Object.entries(obj).filter(predicate))

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
