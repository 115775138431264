import PromoService from '@/services/PromotionService.vue'
export default {
    actions : {
        async fetchPromoDetails(ctx, data) {
            const promoDetails  = await PromoService.methods.GetPromoDetails(data)
            ctx.commit(
                'updatedPromoDetails',
                promoDetails.csGetPromoCodesResponse.PromoCodeList.PromoCodeListItem
            )
        }
    },
    mutations: {
        updatedPromoDetails (state, promoDetails) {
            promoDetails =  promoDetails ? Array.isArray(promoDetails) ? promoDetails : [promoDetails] : []
            const mapPromo = (promo) => {
                Object.values(promo).map((el, key) => {
                    const objectkey = Object.keys(promo)[key]
                    promo[objectkey] = el._text
                })
            }
            promoDetails.map(mapPromo)
            state.promoDetails = promoDetails
            state.getPromoDetailsWithEX_DATE = promoDetails ? (promoDetails.length > 0 ? (promoDetails.filter(el => el.ExpirationDate)) : [] ) : []
        }
    },
    getters : {
        getPromoDetails (state) {
            return state.promoDetails
        },
        getPromoDetailsWithEX_DATE (state) {
            return state.getPromoDetailsWithEX_DATE
        }
    },
    state : {
        promoDetails : null,
        getPromoDetailsWithEX_DATE: []
    }
}